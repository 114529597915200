import { Button, Tooltip, IconButton } from "@material-ui/core";
import { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/products.css";
import "bootstrap/dist/css/bootstrap.min.css";

// import { ImEqualizer2 } from "react-icons/im";
import { BiEditAlt } from "react-icons/bi";
import TablePagination from "@material-ui/core/TablePagination";
import Global from "../global";
// import { IoSearchOutline } from "react-icons/io5";
import { IoMdAddCircle } from "react-icons/io";
// import { AiOutlineCloudDownload } from "react-icons/ai";
import { MdAdd } from "react-icons/md";

import { IconContext } from "react-icons";

import Loader from "react-loader-spinner";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router";
import _ from "lodash";
import emptyRole from "../assets/images/empty-role.png";
import { BsSliders } from "react-icons/bs";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Badge from "@material-ui/core/Badge"
import Popover from "@material-ui/core/Popover";
import { CgClose } from "react-icons/cg";


// add button icon alignment design
const useStyles = makeStyles({
  rootAbsolute: {
    position: "relative",
    fontFamily: "BoldFont",

    "& .MuiButton-startIcon": {
      position: "absolute",
      right: 0,
    },
    "& .MuiButton-label": {
      marginRight: 10,
      transition: "all 0.3s",
    },
  },
});

const Roles = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const [roleData, setRole] = useState([]);
  const [loading, setLoading] = useState(true);
  const [roleAccess, setRoleAccess] = useState({});
  // console.log("loading", loading);
  const [refresh, setRefresh] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  
  //filter states
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterData, setFilterData] = useState({});
   const [search, setSearch] = useState("");
 
   const [selectedStatus, setSelectedStatus] = useState([]);
   const [selectedCities, setSelectedCities] = useState([]);
   const [paginationData, setPaginationData] = useState({});

   const filterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const filterClose = () => {
    setAnchorEl(null);
  };

  
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // table pagination handlechange for number of pages
  const handleChangePage = (event, newPage) => {
    getRole(newPage, rowsPerPage);
  };

  // number of rows handle change for items display
  const handleChangeRowsPerPage = async (event) => {
    getRole(page, event.target.value);
  };

  useEffect(() => {
    const submitStatus =
    selectedStatus &&
    selectedStatus.map((item) => {
      return item === "Active" ? 1 : 0;
    });
    const checkPrivilegeAccess = async () => {
      try {
        const response = await axios.post(
          Global.base_url + "/role/check/privileges",
          null,
          config
        );
        // console.log(response);

        if (response.status === 200) {
          // console.log("role access", response);

          setRoleAccess(response.data);

          console.log(
            "check access",
            _.some(response.data.privileges, ["privilege_name", "ADD ROES"])
          );

          if (
            _.some(response.data.privileges, ["privilege_name", "VIEW ROLES"])
          ) {
            try {
              const getResponse = await axios.post(
                Global.base_url + "/role",
                {  currentPage: page + 1,
                  pageSize: rowsPerPage,
                  search: search,
                  status: submitStatus.length ? submitStatus : [],},
                config
              );
              // console.log(response);

              if (getResponse.status === 200) {
                console.log("role getResponse: ", getResponse);

               
                setRole(getResponse.data.data);
                let sortedStatus = Object.keys(getResponse.data.filter.status).map(
                  (item) => {
                    return {
                      name: item,
                    };
                  }
                );

                setFilterData({
                  status: sortedStatus,

                });
                setPaginationData(getResponse.data.pagination);
                setPage(getResponse.data.pagination.currentPage - 1);
                setRowsPerPage(getResponse.data.pagination.pageSize);
              }
            } catch (error) {
              if (error.response) {
                setLoading(false);
                if (error.response.status === 401) {
                  return toast.error("Unable to get roles");
                }
                if (error.response.status === 500) {
                  return toast.error("Internal server error");
                }
              }
            }
          }
          return setLoading(false);
        }
      } catch (error) {
        if (error.response) {
          setLoading(false);
          if (error.response.status === 401) {
            return toast.error("Unable to get privilege access");
          }
          if (error.response.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
    };

    checkPrivilegeAccess();
  }, [refresh]);

 
  const rowdataAlign = { verticalAlign: "middle" };

  const getRole = async (pageData, rowsPerPageData) => {
    const submitStatus = selectedStatus.map((item) => {
      return item === "Active" ? 1 : 0;
    });
   
    try {
      const response = await axios.post(
        Global.base_url + "/role",
        {
          currentPage: pageData + 1,
          pageSize: rowsPerPageData,
          search: search,
          status: submitStatus.length ? submitStatus : [],
        },
        config
      );


      if (response.status === 200) {
        console.log("get test data", response.data);

        setRole(response.data.data);
        setPaginationData(response.data.pagination);
        setPage(response.data.pagination.currentPage - 1);
        setRowsPerPage(response.data.pagination.pageSize);
        setAnchorEl(null);

        let sortedStatus = Object.keys(response.data.filter.status).map(
          (item) => {
            return {
              name: item,
              // checked: false,
            };
          }
        );

        setFilterData({
          status: sortedStatus,

        });

        // setPaginationData(response.data.pagination);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return toast.error("Unable to get roles");
        }
        if (error.response.status === 500) {
          return toast.error("Internal server error");
        }
      }
    }
  };



  return (
    <>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            // newestOnTop={false}
            closeOnClick
            rtl={false}
          />

          <section className="filter-section testreport ">
            <div className="d-flex justify-content-between align-items-center">
              <div className="sectionHeader p-3">
                <h2 className="hkBoldFont" style={{ color: "#161616" }}>
                  Roles
                </h2>
                <p className="mediumFont mt-2" style={{ color: "#515151" }}>
                  {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. */}
                </p>
              </div>
              {roleAccess && roleAccess.system_features ? (
                <Button
                  variant="contained"
                  color="secondary"
                  className="primary__button"
                  type="submit"
                  // style={{}}
                  onClick={() => {
                    history.push({
                      pathname: "/admin/system-feature",
                    });
                  }}
                >
                  System Feature
                </Button>
              ) : null}
            </div>
            <div className="d-flex align-items-center justify-content-end  flex-md-row  mr-0 pt-2 px-0 pb-0">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  //   border: showFilter ? "1px solid #c3cad9" : 0,
                  borderLeft: "none",
                  borderBottom: "none",
                }}
                className="search__testcontainer"
                // id={open ? "searchTransition" : "searchStatic"}
              >
                <input
                  type="text"
                  name="search"
                  id={"search__input"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search ID, Name, Price"
                  onKeyDown={(e) => {
                    // detects Enter key or numberpad enter key
                    if (e.code === "Enter") {
                      getRole(page, rowsPerPage);
                    }
                  }}
                />
              </div>
              <div
                className="filter__container"
                style={{ backgroundColor: open ? "#F3F3F3" : "#fff" }}
              >
                <div
                  className="filter__icon p-3"
                  onClick={filterClick}
                  style={{ backgroundColor: open ? "#F3F3F3" : "#fff" }}
                >
                  {selectedStatus.length !== 0 ? (
                    <Badge
                      badgeContent={selectedStatus.length}
                      color="primary"
                    >
                      <IconContext.Provider
                        value={{
                          color: "#333",
                          size: "25px",
                        }}
                      >
                        <BsSliders />
                      </IconContext.Provider>
                    </Badge>
                  ) : (
                    <IconContext.Provider
                      value={{
                        color: "#333",
                        size: "25px",
                      }}
                    >
                      <BsSliders />
                    </IconContext.Provider>
                  )}
                </div>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={filterClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: {
                      // width: "45%",
                      backgroundColor: "#f3f3f3",
                      // display: "flex",
                      // justifyContent: 'space-between'
                    },
                  }}
                >
                  <div className="pop__container">
                    {selectedStatus.length ? (
                      <div className="selected__items px-4 pt-4 d-flex align-items-center semiFont">
                        <div className="batch__item d-flex align-items-center">
                          <p className="mr-2 mt-1">
                            {selectedStatus.length}
                          </p>
                          <div
                            className="icons"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedCities([]);
                              setSelectedStatus([]);
                              
                            }}
                          >
                            <IconContext.Provider
                              value={{
                                color: "#fff",
                                size: "20px",
                              }}
                            >
                              <CgClose />
                            </IconContext.Provider>
                          </div>
                        </div>
                        <p className="ml-2">Filteres are selected</p>
                      </div>
                    ) : null}

                    <div className="row px-4 pb-4 pt-4 justify-content-lg-between">
                     
                     
                      <div className="col">
                        <p className="semiFont pop__header">Status</p>
                        <div className="filter__items mt-2 d-flex flex-column">
                          {filterData &&
                            filterData.status &&
                            filterData.status.map((item, i) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        selectedStatus.includes(item.name)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          setSelectedStatus([
                                            ...selectedStatus,
                                            item.name,
                                          ]);
                                        } else {
                                          setSelectedStatus(
                                            selectedStatus &&
                                              selectedStatus.filter((list) => {
                                                return item.name !== list;
                                              })
                                          );
                                        }
                                      }}
                                      name={item.name}
                                      color="primary"
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="carbon__buttoncontainer">
                      {/* {location.state.editable ? ( */}
                      <Button
                        variant="contained"
                        className="carbon__secondarybtn carbon__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          //   padding: "25px ",
                          color: "#fff",
                          //   border: "1px solid #1565d8",
                          //   marginRight: "15px",
                        }}
                        onClick={() => {
                          setSelectedStatus([]);                         
                        }}
                      >
                        <p>Reset Filter</p>
                      </Button>
                      {/* ) : null} */}

                      <Button
                        variant="contained"
                        className="carbon__primarybtn carbon__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          //   padding: "10px 25px",

                          color: "#fff",
                        }}
                        onClick={() => getRole(page, rowsPerPage)}
                      >
                        <p className="carbon__text">Apply Filter</p>
                        {/* Yes */}
                      </Button>
                    </div>
                  </div>
                </Popover>
                {/* </> */}
              </div>
              {roleAccess &&
              _.some(roleAccess.privileges, ["privilege_name", "ADD ROLES"]) ? (
                <Button
                  variant="contained"
                  color="secondary"
                  // className="primary__button"
                  type="submit"
                  startIcon={
                    <IconContext.Provider
                      value={{
                        color: "#fff",
                        size: "25px",
                      }}
                    >
                      <MdAdd />
                    </IconContext.Provider>
                  }
                  style={{ width: "250px" }}
                  className={[classes.rootAbsolute, "primary__button"]}
                  onClick={() => {
                    history.push({
                      pathname: "/admin/role-update",
                      state: {
                        data: null,
                        systemFeature: roleAccess.system_features,
                        location: location.pathname,
                        editable: false,
                      },
                    });
                  }}
                >
                  Add Role
                </Button>
              ) : null}
            </div>
          </section>
          <section
            className="admin-product-filter-section table-responsive fixHeader
"
          >
            <table
              className="table  text-center  admin-product_usertable mb-0"
              style={{
                backgroundColor: "white",
              }}
              id="bill-table"
            >
              <thead>
                <tr
                  id="admin-product-row"
                  style={{ border: "none", backgroundColor: "#E0E0E0" }}
                >
                  <th scope="col" className="admin-product-row_table-header">
                    Role Id
                  </th>
                  <th scope="col" className="admin-product-row-table-header">
                    Role Name
                  </th>

                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Status
                  </th>
                  {roleAccess &&
                  _.some(roleAccess.privileges, [
                    "privilege_name",
                    "EDIT ROLES",
                  ]) ? (
                    <th
                      scope="col"
                      className="admin-product-row-action-col admin-product-row-table-header"
                    ></th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {roleData.length ? (
                  roleData.map((data, i) => {
                    if (i > rowsPerPage - 1) {
                      return;
                    }
                    const color = data.status === 1 ? "#03A33B" : "red";

                    //   const borderLeft = `5px solid ${color}`;
                    const borderBottom = `1px solid #B3CDFF`;
                    const font = "mediumFont";

                    return (
                      <>
                        <tr
                          key={data.role_id + Math.random()}
                          style={{
                            //   borderLeft,
                            borderBottom,
                            backgroundColor: "white",
                          }}
                          className="admin-product-row-table-row-hover "
                        >
                          <td
                            scope="row"
                            id="admin-product-rd"
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            <p
                              data-toggle="collapse"
                              data-target={`#accordion` + i}
                              className="clickable"
                              id="button-open"
                              // style={{ display: "none" }}
                            >
                              <IconContext.Provider
                                value={{
                                  color: "#4059E2",
                                  size: "25px",
                                  style: { marginBottom: "3px" },
                                }}
                              >
                                <IoMdAddCircle className="toggle-row-icon" />
                              </IconContext.Provider>
                            </p>{" "}
                            RID{data.role_id}
                          </td>
                          <td
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            {" "}
                            {data.role_name}
                          </td>

                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={{ color, ...rowdataAlign }}
                          >
                            {data.status ? "Active" : "Inactive"}
                          </td>
                          {roleAccess &&
                          _.some(roleAccess.privileges, [
                            "privilege_name",
                            "EDIT ROLES",
                          ]) ? (
                            <td
                              className="admin-product-row-action-col  py-3"
                              style={rowdataAlign}
                            >
                              <div className="iconButtons__container">
                              <Tooltip title={"Edit"} placement="top-center">
                                  <IconButton
                                   className="edit__IconButton"
                                   onClick={() => {
                                    history.push({
                                      pathname: "/admin/role-update",
                                      state: {
                                        systemFeature: roleAccess.system_features,
                                        data: data,
                                        location: location.pathname,
                                        editable: true,
                                        deletable: _.some(roleAccess.privileges, [
                                          "privilege_name",
                                          "DELETE ROLES",
                                        ]),
                                      },
                                    });
                                  }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        color: "#1565d8",
                                        size: "30px",
                                      }}
                                    >
                                      <BiEditAlt />
                                    </IconContext.Provider>
                                  </IconButton>
                                </Tooltip>
                              </div>
                             
                            </td>
                          ) : null}
                        </tr>
                        <tr
                          id={"accordion" + i}
                          className="collapse  admin-product-hided-row"
                          key={data.role_id + Math.random()}
                        >
                          <td colSpan="6" style={rowdataAlign}>
                            <ul
                              className="admin-product-row-table-hide-list text-left mediumFont"
                              style={{ color: "#525252" }}
                            >
                              <li
                                key={data.role_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Status:
                                  </div>
                                  <div
                                    className="col-6  text-left"
                                    style={{ color }}
                                  >
                                    {data.status ? "Active" : "Inactive"}
                                  </div>
                                </div>
                              </li>
                              {roleAccess &&
                              _.some(roleAccess.privileges, [
                                "privilege_name",
                                "EDIT ROLES",
                              ]) ? (
                                <li
                                  key={data.role_id + Math.random()}
                                  style={{
                                    // borderBottom: "1px solid #B3CDFF",
                                    padding: "10px",
                                  }}
                                >
                                  <div className="row justify-content-center mt-2 ml-3">
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3  text-center iconButtons__container">
                                    <Tooltip title={"Edit"} placement="top-center">
                                  <IconButton
                                   className="edit__IconButton"
                                   onClick={() => {
                                    history.push({
                                      pathname: "/admin/role-update",
                                      state: {
                                        systemFeature: roleAccess.system_features,
                                        data: data,
                                        location: location.pathname,
                                        editable: true,
                                        deletable: _.some(roleAccess.privileges, [
                                          "privilege_name",
                                          "DELETE ROLES",
                                        ]),
                                      },
                                    });
                                  }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        color: "#1565d8",
                                        size: "30px",
                                      }}
                                    >
                                      <BiEditAlt />
                                    </IconContext.Provider>
                                  </IconButton>
                                </Tooltip>
                                    </div>
                                  </div>
                                </li>
                              ) : null}
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <div
                    className="center__items p-5"
                    style={{ flexDirection: "column" }}
                  >
                    <img src={emptyRole} alt="empty-role" />
                    <p
                      style={{
                        color: "#D2D2D2",
                        padding: "20px",
                        fontSize: "20px",
                        fontFamily: "mediumFont",
                      }}
                    >
                      {roleAccess &&
                      _.some(roleAccess.privileges, [
                        "privilege_name",
                        "VIEW ROLES",
                      ])
                        ? "Roles not found..."
                        : "You don't have access"}
                    </p>
                  </div>
                )}
              </tbody>
            </table>
          </section>
          {paginationData && paginationData.totalData > 5 ? (
            <div className="d-flex flex-row align-items-center justify-content-end mb-4">
              <TablePagination
                component="div"
                count={paginationData ? paginationData.totalData : 0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 50]}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Roles;
