import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../assets/css/users.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { HiOutlineDocumentText } from "react-icons/hi";
import { MdLocalHospital } from "react-icons/md";
import TablePagination from "@material-ui/core/TablePagination";
import Global from "../global";
import { IoMdAddCircle } from "react-icons/io";

import { IconContext } from "react-icons";
// import API from "../utills/api";
import Loader from "react-loader-spinner";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router";
import _ from "lodash";
import emptyInvoice from "../assets/images/empty_invoice.png";
// import empty_invoice from "../assets/images/empty_invoice.png";

const useStyles = makeStyles({
  rootAbsolute: {
    position: "relative",
    fontFamily: "BoldFont",

    "& .MuiButton-startIcon": {
      position: "absolute",
      right: 0,
    },
    "& .MuiButton-label": {
      marginRight: 10,
      transition: "all 0.3s",
      "&:hover": {
        marginRight: 30,
      },
    },
  },
});

const Invoices = (props) => {
  const history = useHistory();
  const location = useLocation();
  // console.log("location", location);
  const classes = useStyles();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
  // console.log("AdminProduct", props);
  const [open, setOpen] = useState(false);

  //   const [period, setPeriod] = useState();
  const [status, setStatus] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [allInvoices, setallInvoices] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [invoiceAccess, setInvoiceAccess] = useState({});
  // console.log("loading", loading);
  const [refresh, setRefresh] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const [editData, setEdit] = useState({
    data: {},
    index: "",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    const total = allInvoices.length;
    let startIndex = (newPage + 1) * rowsPerPage - rowsPerPage;

    const endIndex = startIndex + rowsPerPage;
    const data = allInvoices.slice(startIndex, endIndex);

    setInvoices([...data]);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    let startIndex = 1 * event.target.value - event.target.value;

    const endIndex = startIndex + event.target.value;
    const data = allInvoices.slice(startIndex, endIndex);

    setInvoices([...data]);
  };

  useEffect(() => {
    async function fetchInvoices() {
      try {
        const checkInvoice = await axios.post(
          Global.base_url + "/invoice/check/privileges",
          null,
          config
        );

        if (checkInvoice.status === 200) {
          console.log("invoices", checkInvoice);
          setInvoiceAccess(checkInvoice.data);

          if (
            _.some(checkInvoice.data.privileges, [
              "privilege_name",
              "VIEW INVOICE",
            ])
          ) {
            try {
              const response = await axios.post(
                Global.base_url + "/invoice",
                null,
                config
              );

              if (response.status === 200) {
                console.log("invoice", response);
                let datatest = response.data.data;
                const total = datatest.length;
                let startIndex = (page + 1) * rowsPerPage - rowsPerPage;

                const endIndex = startIndex + rowsPerPage;
                const data = datatest.slice(startIndex, endIndex);
                // console.log("products response: ", data);
                setallInvoices(response.data.data);
                setInvoices(data);
              }
            } catch (error) {
              // console.log("errror", error);
              if (error.response) {
                if (error.response.status === 401) {
                  // console.log("admin product error", error);
                  setLoading(true);
                  return toast.error("Unable to get reports");
                }
                if (error.response.status === 500) {
                  // console.log("admin product error", error);
                  setLoading(true);
                  return toast.error("Internal server error");
                }
              }
            }
          }
          return setLoading(false);
        }
      } catch (error) {
        // console.log("errror", error);
        if (error.checkInvoice) {
          if (error.checkInvoice.status === 401) {
            // console.log("admin product error", error);
            // setLoading(true);
            return toast.error("Unable to get invoice access");
          }
          if (error.checkInvoice.status === 500) {
            // console.log("admin product error", error);
            // setLoading(true);
            return toast.error("Internal server error");
          }
        }
      }
    }
    fetchInvoices();
  }, [refresh]);
  // const classfilter=open?"d-flex flex-column "
  const rowdataAlign = { verticalAlign: "middle" };

  return (
    <>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div className="report__container">
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            // newestOnTop={false}
            closeOnClick
            rtl={false}
          />

          <section className="filter-section testreport">
            <div className="sectionHeader p-3">
              <h2 className="hkBoldFont" style={{ color: "#161616" }}>
                Invoices
              </h2>
              <p className="mediumFont mt-2" style={{ color: "#515151" }}>
                {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. */}
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-end  flex-md-row  mr-0 pt-2 px-0 pb-0">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  //   border: showFilter ? "1px solid #c3cad9" : 0,
                  borderLeft: "none",
                  borderBottom: "none",
                }}
                className="search__testcontainer"
                // id={open ? "searchTransition" : "searchStatic"}
              >
                <input
                  type="text"
                  name="search"
                  id={"search__input"}
                  placeholder="Search Invoice"
                />
              </div>
            </div>
          </section>
          <section
            className="admin-product-filter-section table-responsive fixHeader
"
          >
            <table
              className="table  text-center  admin-product_usertable m-0"
              style={{
                backgroundColor: "white",
              }}
              id="bill-table"
            >
              <thead>
                <tr
                  id="admin-product-row"
                  style={{ border: "none", backgroundColor: "#E0E0E0" }}
                >
                  <th scope="col" className="admin-product-row_table-header">
                    Invoice ID
                  </th>
                  <th scope="col" className="admin-product-row-table-header">
                    Invoice Name
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header admin-product-row-price-col"
                  >
                    Order ID
                  </th>

                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Total
                  </th>
                  {invoiceAccess &&
                  _.some(invoiceAccess.privileges, [
                    "privilege_name",
                    "VIEW INVOICE",
                  ]) ? (
                    <th
                      scope="col"
                      className="admin-product-row-action-col admin-product-row-table-header"
                    ></th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {invoices.length > 0 ? (
                  invoices.map((data, i) => {
                    if (i > rowsPerPage - 1) {
                      return;
                    }
                    const borderBottom = `1px solid #B3CDFF`;

                    // const font = "mediumFont";

                    return (
                      <>
                        <tr
                          key={data.invoice_id}
                          style={{
                            //   borderLeft,
                            borderBottom,
                            backgroundColor: "white",
                          }}
                          className="admin-product-row-table-row-hover "
                        >
                          <td
                            scope="row"
                            id="admin-product-rd"
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            <p
                              data-toggle="collapse"
                              data-target={`#accordion` + data.invoice_id}
                              className="clickable"
                              id="button-open"
                              // style={{ display: "none" }}
                            >
                              <IconContext.Provider
                                value={{
                                  color: "#4059E2",
                                  size: "25px",
                                  style: { marginBottom: "3px" },
                                }}
                              >
                                <IoMdAddCircle className="toggle-row-icon" />
                              </IconContext.Provider>
                            </p>{" "}
                            IVID{data.invoice_id}
                          </td>
                          <td
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            {data.invoice_name}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.order_id}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            &#8377; {Number(data.total1) + Number(data.total2)}
                          </td>

                          <td
                            className="admin-product-row-action-col admin-product-row-table-row-data "
                            // colSpan={1}
                            style={rowdataAlign}
                          >
                            <Button
                              // key={"view" + i}
                              variant="contained"
                              color="default"
                              className={"admin-product-Filter__button mr-2"}
                              // startIcon={<AiOutlineCloudDownload color="#2C3E50" />}
                              style={{ padding: "10px 20px" }}
                              onClick={() => {
                                // toast.success("Hello");
                                history.push({
                                  pathname: "/admin/invoice-view",
                                  state: {
                                    data: data,
                                    location: location.pathname,
                                  },
                                });
                              }}
                              startIcon={<HiOutlineDocumentText />}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                        <tr
                          // key={data.invoice_id}
                          id={"accordion" + data.invoice_id}
                          className="collapse  admin-product-hided-row"
                          // key={i}
                        >
                          <td colSpan="6" style={rowdataAlign}>
                            <ul className="admin-product-row-table-hide-list text-left ">
                              <li
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start">
                                  <div className="col-6  text-center">
                                    Order ID :
                                  </div>
                                  <div
                                    className="col-6  text-left"
                                    style={{ wordBreak: "break-word" }}
                                  >
                                    {data.order_id}
                                  </div>
                                </div>
                              </li>
                              <li
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start">
                                  <div className="col-6  text-center">
                                    Total :
                                  </div>
                                  <div className="col-6  text-left">
                                    &#8377;{data.total}
                                  </div>
                                </div>
                              </li>

                              <li
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-center mt-2 ml-3">
                                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3  text-center">
                                    <Button
                                      variant="contained"
                                      color="default"
                                      className={
                                        "admin-product-Filter__button mr-2"
                                      }
                                      onClick={() => {
                                        // toast.success("Hello");
                                        history.push({
                                          pathname: "/admin/invoice-view",
                                          state: {
                                            data: data,
                                            location: location.pathname,
                                          },
                                        });
                                      }}
                                      startIcon={<HiOutlineDocumentText />}
                                      // onClick={() => {
                                      //   setBillView({ isView: true, data });
                                      // }}
                                    >
                                      View
                                    </Button>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <div
                    className="center__items p-5"
                    style={{ flexDirection: "column" }}
                  >
                    <img src={emptyInvoice} alt="Empty-incoice" />
                    <p
                      style={{
                        color: "#D2D2D2",
                        padding: "20px",
                        fontSize: "20px",
                        fontFamily: "mediumFont",
                      }}
                    >
                      {invoiceAccess &&
                      _.some(invoiceAccess.privileges, [
                        "privilege_name",
                        "VIEW INVOICE",
                      ])
                        ? "Invoices not found..."
                        : "You don't have access"}
                    </p>
                  </div>
                )}
              </tbody>
            </table>
          </section>
          {allInvoices.length > 5 ? (
            <div className="d-flex flex-row align-items-center justify-content-end mb-4">
              <TablePagination
                component="div"
                count={allInvoices.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 50]}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Invoices;
