import { Button, Tooltip, IconButton } from "@material-ui/core";
import React, { useState, useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/products.css";
import { CgClose } from "react-icons/cg";
import "bootstrap/dist/css/bootstrap.min.css";
import { BiEditAlt } from "react-icons/bi";
import Popover from "@material-ui/core/Popover";

import TablePagination from "@material-ui/core/TablePagination";
import Global from "../global";
// import { IoSearchOutline } from "react-icons/io5";
import { IoMdAddCircle } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";
import { MdAdd } from "react-icons/md";

import { IconContext } from "react-icons";

// import API from "../utills/api";
import Loader from "react-loader-spinner";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import emptyTest from "../assets/images/empty-test.png";
import { BsSliders } from "react-icons/bs";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Badge from "@material-ui/core/Badge";
import { useHistory } from "react-router";
import DeleteModal from "./DeleteModal";
const statusOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

const testOptions = [
  { value: "Walk In", label: "Walk In" },
  { value: "Home Test", label: "Home Test" },
  // { value: "Kit Delivery", label: "Kit Delivery" },
];

// add button icon alignment design
const useStyles = makeStyles({
  rootAbsolute: {
    position: "relative",
    fontFamily: "BoldFont",

    "& .MuiButton-startIcon": {
      position: "absolute",
      right: 0,
    },
    "& .MuiButton-label": {
      marginRight: 10,
      transition: "all 0.3s",
    },
  },
});

const Tests = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  // console.log(config)
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
  // console.log("AdminProduct", props);
  //   const [period, setPeriod] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  // const [allProducts, setAllProducts] = useState([]);
  const [productData, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [testAccess, setTestAccess] = useState({});
  const [filterData, setFilterData] = useState({});
  const [paginationData, setPaginationData] = useState({});
  console.log("loading", loading);
  const [refresh, setRefresh] = useState("");

  const [editProduct, setEdit] = useState({
    data: {},
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  console.log("pageasgk.jdjaksjdgkasjdgkajsgdkasjvhdjh", [page, rowsPerPage]);

  // console.log("rowsperPage", rowsPerPage);

  //filter states
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedType, setSelectedType] = useState([]);

  console.log("aselectedTypenchor", [selectedStatus, selectedType]);

  const filterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const filterClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // table pagination handlechange for number of pages
  const handleChangePage = (event, newPage) => {
    console.log("newpage", newPage - 1);
    // setPage(newPage);
    getTest(newPage, rowsPerPage);

    // const total = allProducts.length;
    // let startIndex = (newPage + 1) * rowsPerPage - rowsPerPage;

    // const endIndex = startIndex + rowsPerPage;
    // const data = allProducts.slice(startIndex, endIndex);

    // setProduct([...data]);
  };

  // number of rows handle change for items display
  const handleChangeRowsPerPage = async (event) => {
    console.log("page change", event.target.value);
    // setRowsPerPage(event.target.value);
    getTest(page, event.target.value);
  };

  useEffect(() => {
    const submitStatus =
      selectedStatus &&
      selectedStatus.map((item) => {
        return item === "Active" ? 1 : 0;
      });
    async function fetchProduct() {
      try {
        const checkTest = await axios.post(
          Global.base_url + "/test/check/privileges",
          null,
          config
        );
        // console.log(response);

        if (checkTest.status === 200) {
          console.log("checkTest", checkTest);
          setTestAccess(checkTest.data);
          // console.log("view access");

          if (
            _.some(checkTest.data.privileges, ["privilege_name", "VIEW TEST"])
          ) {
            try {
              const response = await axios.post(
                Global.base_url + "/test",
                {
                  currentPage: page + 1,
                  pageSize: rowsPerPage,
                  search: search,
                  test_type: selectedType.length ? selectedType : [],
                  status: submitStatus.length ? submitStatus : [],
                },
                config
              );
              // console.log(response);

              if (response.status === 200) {
                console.log("test response: ", response);

                setProduct(response.data.data);

                let sortedStatus = Object.keys(response.data.filter.status).map(
                  (item) => {
                    return {
                      name: item,
                      // checked: false,
                    };
                  }
                );

                let sortedType = response.data.filter.test_type.map((item) => {
                  return {
                    name: item,
                    checked: false,
                  };
                });
                setFilterData({
                  status: sortedStatus,
                  type: sortedType,
                });
                setPaginationData(response.data.pagination);
                setPage(response.data.pagination.currentPage - 1);
                setRowsPerPage(response.data.pagination.pageSize);
              }
            } catch (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  toast.error("Unable to get tests");
                }
                if (error.response.status === 500) {
                  toast.error("Internal server error");
                }
              }
            }
          }
          return setLoading(false);
          //  }
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            return toast.error("Unable to get test access");
          }
          if (error.response.status === 500) {
            return toast.error("Internal server error");
          }
        }
      }
    }
    fetchProduct();
  }, [refresh]);

  console.log("filterData", [filterData, paginationData]);
  // const classfilter=open?"d-flex flex-column "
  const rowdataAlign = { verticalAlign: "middle" };

  // const deleteProduct = async () => {
  //   try {
  //     const response = await axios.post(
  //       Global.base_url + "/test/delete",
  //       {
  //         test_id: editProduct.data.test_id,
  //       },
  //       config2
  //     );

  //     // console.log(response);

  //     if (response.status === 200) {
  //       await closeModal();
  //       toast.success("Test Deleted Successfully");

  //       return setRefresh(Date.now() + 1);
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       toast.error("Test Deletion failed");
  //     }

  //     if (error.response && error.response.status === 500) {
  //       toast.error("Internal server error");
  //     }
  //     // return console.log("admin delete fetch error", error);
  //   }
  // };

  const getTest = async (pageData, rowsPerPageData) => {
    const submitStatus = selectedStatus.map((item) => {
      return item === "Active" ? 1 : 0;
    });
    try {
      const response = await axios.post(
        Global.base_url + "/test",
        {
          currentPage: pageData + 1,
          pageSize: rowsPerPageData,
          search: search,
          test_type: selectedType.length ? selectedType : [],
          status: submitStatus.length ? submitStatus : [],
        },
        config
      );
      // console.log(response);

      if (response.status === 200) {
        console.log("get test data", response.data);

        setProduct(response.data.data);
        setPaginationData(response.data.pagination);
        setPage(response.data.pagination.currentPage - 1);
        setRowsPerPage(response.data.pagination.pageSize);
        setAnchorEl(null);

        let sortedStatus = Object.keys(response.data.filter.status).map(
          (item) => {
            return {
              name: item,
              checked: false,
            };
          }
        );

        let sortedType = response.data.filter.test_type.map((item) => {
          return {
            name: item,
            checked: false,
          };
        });
        setFilterData({
          status: sortedStatus,
          type: sortedType,
        });

        setPaginationData(response.data.pagination);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return toast.error("Unable to get tests");
        }
        if (error.response.status === 500) {
          return toast.error("Internal server error");
        }
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            // newestOnTop={false}
            closeOnClick
            rtl={false}
          />

          <DeleteModal
            show={deleteModal}
            onHide={() => setDeleteModal(false)}
            selectedData={editProduct.data.test_id}
            refresh={setRefresh}
            apiUrl={"/test/delete"}
            name={"Test"}
            // id={"category_id"}
          />

          <section className="filter-section testreport">
            <div className="sectionHeader p-3">
              <h2 className="hkBoldFont" style={{ color: "#161616" }}>
                Test
              </h2>
              <p className="mediumFont mt-2" style={{ color: "#515151" }}>
                {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. */}
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-end  flex-md-row  mr-0 pt-2 px-0 pb-0">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  //   border: showFilter ? "1px solid #c3cad9" : 0,
                  borderLeft: "none",
                  borderBottom: "none",
                }}
                className="search__testcontainer"
                // id={open ? "searchTransition" : "searchStatic"}
              >
                <input
                  type="text"
                  name="search"
                  id={"search__input"}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search ID, Name, Test for"
                  onKeyDown={(e) => {
                    // detects Enter key or numberpad enter key
                    if (e.code === "Enter") {
                      getTest(page, rowsPerPage);
                    }
                  }}
                />
              </div>
              <div
                className="filter__container"
                style={{ backgroundColor: open ? "#F3F3F3" : "#fff" }}
              >
                <div
                  className="filter__icon p-3"
                  onClick={filterClick}
                  style={{ backgroundColor: open ? "#F3F3F3" : "#fff" }}
                >
                  {selectedType.length + selectedStatus.length !== 0 ? (
                    <Badge
                      badgeContent={selectedType.length + selectedStatus.length}
                      color="primary"
                    >
                      <IconContext.Provider
                        value={{
                          color: "#333",
                          size: "25px",
                        }}
                      >
                        <BsSliders />
                      </IconContext.Provider>
                    </Badge>
                  ) : (
                    <IconContext.Provider
                      value={{
                        color: "#333",
                        size: "25px",
                      }}
                    >
                      <BsSliders />
                    </IconContext.Provider>
                  )}
                </div>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={filterClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: {
                      // width: "45%",
                      backgroundColor: "#f3f3f3",
                      // display: "flex",
                      // justifyContent: 'space-between'
                    },
                  }}
                >
                  <div className="pop__container">
                    {selectedType.length + selectedStatus.length !== 0 ? (
                      <div className="selected__items px-4 pt-4 d-flex align-items-center semiFont">
                        <div className="batch__item d-flex align-items-center">
                          <p className="mr-2 mt-1">
                            {selectedType.length + selectedStatus.length}
                          </p>
                          <div
                            className="icons"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedType([]);
                              setSelectedStatus([]);
                            }}
                          >
                            <IconContext.Provider
                              value={{
                                color: "#fff",
                                size: "20px",
                              }}
                            >
                              <CgClose />
                            </IconContext.Provider>
                          </div>
                        </div>
                        <p className="ml-2">Filteres are selected</p>
                      </div>
                    ) : null}

                    <div className="row px-4 pb-4 pt-4 justify-content-lg-between">
                      <div className="col">
                        <p className="semiFont pop__header">Test Type</p>
                        <div className="filter__items mt-2 d-flex flex-column">
                          {filterData &&
                            filterData.type &&
                            filterData.type.map((item, i) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        selectedType.includes(item.name)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          setSelectedType([
                                            ...selectedType,
                                            item.name,
                                          ]);
                                        } else {
                                          setSelectedType(
                                            selectedType &&
                                              selectedType.filter((list) => {
                                                return item.name !== list;
                                              })
                                          );
                                        }
                                      }}
                                      name={item.name}
                                      color="primary"
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })}
                        </div>
                      </div>
                      <Divider orientation="vertical" flexItem />
                      <div className="col">
                        <p className="semiFont pop__header">Status</p>
                        <div className="filter__items mt-2 d-flex flex-column">
                          {filterData &&
                            filterData.status &&
                            filterData.status.map((item, i) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        selectedStatus.includes(item.name)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          setSelectedStatus([
                                            ...selectedStatus,
                                            item.name,
                                          ]);
                                        } else {
                                          setSelectedStatus(
                                            selectedStatus &&
                                              selectedStatus.filter((list) => {
                                                return item.name !== list;
                                              })
                                          );
                                        }
                                      }}
                                      name={item.name}
                                      color="primary"
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="carbon__buttoncontainer">
                      {/* {location.state.editable ? ( */}
                      <Button
                        variant="contained"
                        className="carbon__secondarybtn carbon__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          //   padding: "25px ",
                          color: "#fff",
                          //   border: "1px solid #1565d8",
                          //   marginRight: "15px",
                        }}
                        onClick={() => {
                          setSelectedType([]);
                          setSelectedStatus([]);
                        }}
                      >
                        <p>Reset Filter</p>
                      </Button>
                      {/* ) : null} */}

                      <Button
                        variant="contained"
                        className="carbon__primarybtn carbon__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          //   padding: "10px 25px",

                          color: "#fff",
                        }}
                        onClick={() => getTest(page, rowsPerPage)}
                      >
                        <p className="carbon__text">Apply Filter</p>
                        {/* Yes */}
                      </Button>
                    </div>
                  </div>
                </Popover>
                {/* </> */}
              </div>
              {testAccess &&
              _.some(testAccess.privileges, ["privilege_name", "ADD TEST"]) ? (
                <Button
                  variant="contained"
                  color="secondary"
                  // className="primary__button"
                  type="submit"
                  startIcon={
                    <IconContext.Provider
                      value={{
                        color: "#fff",
                        size: "25px",
                      }}
                    >
                      <MdAdd />
                    </IconContext.Provider>
                  }
                  style={{ width: "250px" }}
                  className={[classes.rootAbsolute, "primary__button"]}
                  onClick={() => {
                    history.push({
                      pathname: "/admin/test-view",
                      state: {
                        editable: false,
                        data: null,
                      },
                    });
                    // setEdit({
                    //   data: "",
                    //   index: "",
                    // });
                    // setShowEditModal(true);
                  }}
                >
                  Add Test
                </Button>
              ) : null}
              {/* <div>
                  <Select
                    value={status}
                    onChange={(v) => {
                      filter(v);
                      setStatus(v);
                    }}
                    placeholder="Status"
                    options={statusfilterOptions}
                    className="ub-dropdown-periods ub-filter_input-small"
                  />
                </div> */}
              {/* </div> */}
            </div>
          </section>
          <section
            className="admin-product-filter-section table-responsive fixHeader
"
          >
            <table
              className="table  text-center  admin-product_usertable mb-0"
              style={{
                backgroundColor: "white",
              }}
              id="bill-table"
            >
              <thead>
                <tr
                  id="admin-product-row"
                  style={{ border: "none", backgroundColor: "#E0E0E0" }}
                >
                  <th scope="col" className="admin-product-row_table-header">
                    ID
                  </th>
                  <th scope="col" className="admin-product-row-table-header">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header admin-product-row-price-col"
                  >
                    Test Type
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header admin-product-row-price-col"
                  >
                    Test For
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-price-col"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Status
                  </th>
                  {_.some(testAccess.privileges, [
                    "privilege_name",
                    "EDIT TEST",
                  ]) ||
                  _.some(testAccess.privileges, [
                    "privilege_name",
                    "DELETE TEST",
                  ]) ? (
                    <th
                      scope="col"
                      className="admin-product-row-action-col admin-product-row-table-header"
                    ></th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {productData.length ? (
                  productData.map((data, i) => {
                    if (i > rowsPerPage - 1) {
                      return;
                    }
                    const color = data.status === 1 ? "#03A33B" : "red";

                    //   const borderLeft = `5px solid ${color}`;
                    const borderBottom = `1px solid #B3CDFF`;
                    const font = "mediumFont";

                    return (
                      <>
                        <tr
                          key={data.test_id + Math.random()}
                          style={{
                            //   borderLeft,
                            borderBottom,
                            backgroundColor: "white",
                          }}
                          className="admin-product-row-table-row-hover "
                        >
                          <td
                            scope="row"
                            id="admin-product-rd"
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            <p
                              data-toggle="collapse"
                              data-target={`#accordion` + i}
                              className="clickable"
                              id="button-open"
                              // style={{ display: "none" }}
                            >
                              <IconContext.Provider
                                value={{
                                  color: "#4059E2",
                                  size: "25px",
                                  style: { marginBottom: "3px" },
                                }}
                              >
                                <IoMdAddCircle className="toggle-row-icon" />
                              </IconContext.Provider>
                            </p>{" "}
                            TID{data.test_id}
                          </td>
                          <td
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            {" "}
                            {data.test_name}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.test_type}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.test_for}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            &#8377; {data.offer_price}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={{ color, ...rowdataAlign }}
                          >
                            {data.status ? "Active" : "Inactive"}
                          </td>
                          {_.some(testAccess.privileges, [
                            "privilege_name",
                            "EDIT TEST",
                          ]) ||
                          _.some(testAccess.privileges, [
                            "privilege_name",
                            "DELETE TEST",
                          ]) ? (
                            <td
                              className="admin-product-row-action-col py-3 "
                              colSpan={1.5}
                              style={rowdataAlign}
                            >
                              <div className="iconButtons__container">
                              {_.some(testAccess.privileges, [
                                "privilege_name",
                                "EDIT TEST",
                              ]) ? (
                                <Tooltip title={"Edit"} placement="top-center">
                                  <IconButton
                                    className="edit__IconButton"
                                    onClick={() => {
                                      history.push({
                                        pathname: "/admin/test-view",
                                        state: {
                                          editable: true,
                                          data: data,
                                        },
                                      });
                                    }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        color: "#1565d8",
                                        size: "30px",
                                      }}
                                    >
                                      <BiEditAlt />
                                    </IconContext.Provider>
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                              {_.some(testAccess.privileges, [
                                "privilege_name",
                                "DELETE TEST",
                              ]) ? (
                                <Tooltip
                                  title={"Remove"}
                                  placement="top-center"
                                >
                                  <IconButton
                                  className="delete__IconButton"
                                  
                                    onClick={() => {
                                      setEdit({
                                        data: data,
                                      });
                                      setDeleteModal(true);
                                      // deleteReport(
                                      //   uploadedReports.combined[0]
                                      // );
                                    }}
                                  >
                                    <IconContext.Provider
                                      value={{
                                        color: "#cc0000",
                                        size: "30px",
                                      }}
                                    >
                                      <AiOutlineDelete />
                                    </IconContext.Provider>
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                              </div>
                              
                            </td>
                          ) : null}
                        </tr>
                        <tr
                          id={"accordion" + i}
                          className="collapse  admin-product-hided-row"
                          key={data.test_id + Math.random()}
                        >
                          <td colSpan="6" style={rowdataAlign}>
                            <ul
                              className="admin-product-row-table-hide-list text-left mediumFont mb-0"
                              style={{ color: "#525252" }}
                            >
                              <li
                                key={data.test_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Test type
                                  </div>
                                  <div className="col-6  text-left">
                                    {data.test_type}
                                  </div>
                                </div>
                              </li>
                              <li
                                key={data.test_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Test For
                                  </div>
                                  <div className="col-6  text-left">
                                    {data.test_for}
                                  </div>
                                </div>
                              </li>
                              <li
                                key={i + data.test_id + i + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Price:
                                  </div>
                                  <div className="col-6  text-left">
                                    &#8377;{data.offer_price}
                                  </div>
                                </div>
                              </li>
                              <li
                                key={data.test_id + Math.random()}
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Status:
                                  </div>
                                  <div
                                    className="col-6  text-left"
                                    style={{ color }}
                                  >
                                    {data.status ? "Active" : "Inactive"}
                                  </div>
                                </div>
                              </li>
                              {_.some(testAccess.privileges, [
                                "privilege_name",
                                "EDIT TEST",
                              ]) ||
                              _.some(testAccess.privileges, [
                                "privilege_name",
                                "DELETE TEST",
                              ]) ? (
                                <li
                                  key={data.test_id + Math.random()}
                                  style={{
                                    // borderBottom: "1px solid #B3CDFF",
                                    padding: "10px",
                                  }}
                                >
                                  <div className="row justify-content-center mt-2 ml-3">
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3  text-center iconButtons__container">
                                      {_.some(testAccess.privileges, [
                                        "privilege_name",
                                        "EDIT TEST",
                                      ]) ? (
                                        <Tooltip
                                          title={"Edit"}
                                          placement="top-center"
                                        >
                                          <IconButton
                                            className="edit__IconButton"
                                            onClick={() => {
                                              history.push({
                                                pathname: "/admin/test-view",
                                                state: {
                                                  editable: true,
                                                  data: data,
                                                },
                                              });
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                color: "#1565d8",
                                                size: "30px",
                                              }}
                                            >
                                              <BiEditAlt />
                                            </IconContext.Provider>
                                          </IconButton>
                                        </Tooltip>
                                      ) : null}
                                      {_.some(testAccess.privileges, [
                                        "privilege_name",
                                        "DELETE TEST",
                                      ]) ? (
                                        <Tooltip
                                          title={"Remove"}
                                          placement="top-center"
                                        >
                                          <IconButton
                                             className="delete__IconButton"
                                            onClick={() => {
                                              setEdit({
                                                data: data,
                                              });
                                              setDeleteModal(true);
                                              // deleteReport(
                                              //   uploadedReports.combined[0]
                                              // );
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                color: "#cc0000",
                                                size: "30px",
                                              }}
                                            >
                                              <AiOutlineDelete />
                                            </IconContext.Provider>
                                          </IconButton>
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                  </div>
                                </li>
                              ) : null}
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  // <tr>
                  //   <td colSpan={6}>
                  <div
                    className="center__items p-5"
                    style={{ flexDirection: "column" }}
                  >
                    <img src={emptyTest} alt="empty-test" />
                    <p
                      style={{
                        color: "#D2D2D2",
                        padding: "20px",
                        fontSize: "20px",
                        fontFamily: "mediumFont",
                      }}
                    >
                      {testAccess &&
                      _.some(testAccess.privileges, [
                        "privilege_name",
                        "VIEW TEST",
                      ])
                        ? "Tests not found..."
                        : "You don't have access"}
                    </p>
                  </div>
                )}
              </tbody>
            </table>
          </section>
          {paginationData && paginationData.totalData > 5 ? (
            <div className="d-flex flex-row align-items-center justify-content-end mb-4">
              <TablePagination
                component="div"
                count={paginationData ? paginationData.totalData : 0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 50]}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Tests;
