import { useState, useEffect, useContext } from "react";
import "../assets/css/sidebar.css";
import { CgMenu } from "react-icons/cg";
import {
  RiNotification3Line,
  RiUserSettingsLine,
  RiUserLine,
} from "react-icons/ri";
import { AiOutlineHome } from "react-icons/ai";
import {
  RiBarChart2Line,
  RiSettings2Line,
  RiLogoutBoxRLine,
  RiStethoscopeFill,
} from "react-icons/ri";
import { BsFileEarmarkMedical } from "react-icons/bs";
import { GoFile, GoPackage } from "react-icons/go";
import { FiUsers } from "react-icons/fi";
import { GiTestTubes } from "react-icons/gi";
import { FaUserLock } from "react-icons/fa";
import { IconContext } from "react-icons";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { FaUserDoctor } from "react-icons/fa6";
import LoginLogo from "../assets/images/healthmo.png";
import User from "../assets/images/user.png";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useRouteMatch,
  Link,
  Redirect,
} from "react-router-dom";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { List, ListItem, Collapse } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Labs from "./Labs";
import Home from "./Home";
import Invoices from "./Invoices";
import Members from "./Members";
import Reports from "./Reports";
import Tests from "./Tests";
import Settings from "./Settings";
import Packages from "./Packages";
import InvoiceView from "./InvoiceView";
import TestView from "./TestView";

import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import Global from "../global";
import Loader from "react-loader-spinner";
import Roles from "./Roles";
import RoleEdit from "./RoleEdit";
import SystemFeature from "./SystemFeature";
import ReportView from "./ReportView";
import { GlobalContext } from "../context/Context";
import Users from "./Users";
import UserView from "./UserView";
import UserReportView from "./UserReportView";
import Classifications from "./Classifications";
import PackageView from "./PackageView";
import LabView from "./LabView";
import Doctors from "./Doctors";
import DoctorView from "./DoctorView";
import { MdEvent } from "react-icons/md";
import Events from "./Events";
import Departments from "./Departments";
import Holidays from "./Holidays";
import Appointments from "./Appointments";
import { FaRegCalendarCheck } from "react-icons/fa6";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { LuLayoutDashboard } from "react-icons/lu";
import { useHistory, useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: "#fff",
  },

  itemRow: {
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  iconActive: {
    fontSize: "20px",
  },
  itemContainer: {
    padding: "15px 5px",
    margin: "0 auto",
    "&:hover": {
      color: "rgba(23, 23, 37, .7)",
    },
  },
}));

const Sidebar = () => {
  const history = useHistory();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const classes = useStyles();
  let { path } = useRouteMatch();
  let location = useLocation();

  // Get the current full path

  const sidebarCollapsed = localStorage.getItem("sidebar-collapsed");
  const activeSidebar = localStorage.getItem("active-sidebar");
  const activeMenuSidebar = localStorage.getItem("active-subsidebar");
  const [loading, setLoading] = useState(true);
  const [logOutModal, setLogoutModal] = useState(false);
  const [screens, setScreens] = useState([]);
  const [currentLocation, setLocations] = useState(
    location?.pathname?.split("/admin/")?.[1]
  );
  const [minimizeSidebar, setMinimizeSidebar] = useState(
    sidebarCollapsed ? false : true
  );
  const [refresh, setRefresh] = useState("");
  const [collapseOpen, setCollapseOpen] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
  const { image_url, setImage_url } = useContext(GlobalContext);
  // console.log({ path, screens, selectedIndex, activeSidebar });
  useEffect(() => {
    async function getAdminScreens() {
      try {
        const response = await axios.post(
          Global.base_url + "/screen",
          null,
          config
        );

        if (response.status === 200) {
          let screens = response.data.screens;
          console.log(
            "screens data",
            Number(activeSidebar),
            screens?.length + 2
          );
          setSelectedIndex(
            Number(activeSidebar) <= screens?.length + 2
              ? Number(activeSidebar)
              : 0
          );
          setLocations(activeMenuSidebar);
          setScreens(screens);

          setLoading(false);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            setLoading(true);
            return toast.error(error.response.data.Msg);
          }
          if (error.response.status === 500) {
            setLoading(true);
            return toast.error("Internal server error");
          }
        }
      }
      try {
        const imageResponse = await axios.post(
          Global.base_url + "/profile/image/load",
          null,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "blob",
          }
        );
        // console.log(response);

        if (imageResponse.status === 200) {
          console.log('imageResponse', imageResponse);
          // setImage_url(imageResponse.data);
          let imageBlob = URL.createObjectURL(imageResponse.data);
          console.log("image get reponse", imageBlob);
          // toast.error("Image success");
          setImage_url(imageBlob);
        }
      } catch (error) {
        if (error.imageResponse) {
          if (error.imageResponse.status === 401) {
            toast.error("Unable to get User image");
            console.log("admin user error", error);
            return;
          }
        }
      }
    }
    getAdminScreens();
  }, [refresh]);
  console.log({ currentLocation });
  const handleListItemClick = (event, index, menu = "") => {
    setSelectedIndex(Number(index));
    localStorage.setItem("active-sidebar", Number(index));
    localStorage.setItem("active-subsidebar", menu);
    if (isTabletOrMobile) setMinimizeSidebar(false);
  };

  const handleMinimize = () => {
    if (minimizeSidebar) {
      setMinimizeSidebar(false);
      localStorage.setItem("sidebar-collapsed", true);
      return;
    }
    setMinimizeSidebar(true);
    localStorage.removeItem("sidebar-collapsed");
  };

  const VerticaLogoutModal = () => {
    const handleLogout = () => {
      localStorage.clear();
      window.location.href = "/";
    };
    return (
      <Modal
        show={logOutModal}
        onHide={() => setLogoutModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter boldFont">
            {/* {selectedDelete && selectedDelete.assignment_type} */}
            Log Out
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" py-5 semiFont">
          Do you want to Logout ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => handleLogout()}
            style={{
              backgroundColor: "#1565d8",
              width: "100px",
              textTransform: "initial",
              boxShadow: "0 2px 5px #00000029",
              color: "#fff",
              marginRight: "15px",
            }}
          >
            Confirm
          </Button>
          <Button
            // id="modal__submitBtn"
            onClick={() => setLogoutModal(false)}
            style={{
              width: "100px",
              color: "#1565d8",
              border: "1px solid #1565d8",
              textTransform: "initial",
              boxShadow: "0 2px 5px #00000029",
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        // newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <VerticaLogoutModal
        show={logOutModal}
        onHide={() => setLogoutModal(false)}
      />

      <main className="sidebar__container">
        <header className="sidebar__headerWrapper">
          <section className="sidebar__header">
            <div className="sidebar__headerContainer">
              <div className="sidebar__logoSection">
                <div className="sidebar__menu">
                  <IconButton onClick={handleMinimize}>
                    <IconContext.Provider
                      value={{
                        color: "#000",
                        size: "35px",
                      }}
                    >
                      <CgMenu />
                    </IconContext.Provider>
                  </IconButton>
                </div>

                <img
                  src={LoginLogo}
                  alt="Ampath logo"
                  className="sidebar__logo"
                />
              </div>
              <div className="sidebar__userDetails">
                <IconContext.Provider
                  value={{
                    color: "#898989",
                    size: "30px",
                  }}
                >
                  <RiNotification3Line />
                </IconContext.Provider>
                <div className="sidebar__userDropdown">
                  <div className="user__avatarContainer">
                    <img
                      src={image_url}
                      alt="userAvator"
                      className="user__avatar"
                    />
                  </div>
                  {/* <div
                  className="user__dropdown"
                  // onClick={() => setExpand(!expand)}
                >
                  <p className="user__name semiFont">
                    {localStorage.getItem("admin_name")}
                  </p>
                </div> */}
                </div>
              </div>
            </div>
          </section>
        </header>
        {/* <section className="sidebar__row"> */}
        <section
          className={
            minimizeSidebar ? "sidebar__section" : "sidebar__section--mini"
          }
        >
          <div className="sidebar__list">
            {loading ? (
              <div className="center__items" style={{ marginTop: "100px" }}>
                <Loader
                  type="TailSpin"
                  color="#1565d8"
                  height={35}
                  width={35}
                />
              </div>
            ) : (
              <List className="itemRow">
                {screens &&
                  screens.map((list, i) => {
                    return (
                      list.feature_name !== "EVENTS" &&
                      list.feature_name !== "DEPARTMENTS" &&
                      list.feature_name !== "HOLIDAYS" && (
                        <ListItem
                          key={i}
                          button
                          component={NavLink}
                          selected={Number(selectedIndex) === i}
                          to={`/admin/${list.feature_name?.toLowerCase()}`}
                          // activeClassName="active__icon"
                          className={classes.itemContainer}
                          style={{
                            backgroundColor:
                              Number(selectedIndex) === i
                                ? "rgba(0, 98, 255, .10)"
                                : "#fff",
                            borderLeft:
                              Number(selectedIndex) === i
                                ? "3px solid rgba(0, 88, 255, 1)"
                                : "3px solid #fff",
                          }}
                          onClick={(event) => {
                            handleListItemClick(event, i);
                          }}
                        >
                          <div className="sidebar__icons">
                            <IconContext.Provider
                              value={{
                                color:
                                  selectedIndex === i
                                    ? "rgba(0, 88, 255, 1)"
                                    : "#777",
                                size: "30px",
                              }}
                            >
                              {list.feature_name === "DASHBOARD" ? (
                                <AiOutlineHome />
                              ) : list.feature_name === "TESTS" ? (
                                <GiTestTubes />
                              ) : list.feature_name === "PACKAGES" ? (
                                <GoPackage />
                              ) : list.feature_name === "LABS" ? (
                                <RiBarChart2Line />
                              ) : list.feature_name === "ROLES" ? (
                                <FaUserLock />
                              ) : list.feature_name === "REPORTS" ? (
                                <GoFile />
                              ) : list.feature_name === "INVOICES" ? (
                                <BsFileEarmarkMedical />
                              ) : list.feature_name === "MEMBERS" ? (
                                <RiUserSettingsLine />
                              ) : list.feature_name === "DOCTORS" ? (
                                <RiStethoscopeFill />
                              ) : list.feature_name === "USERS" ? (
                                <RiUserLine />
                              ) : list.feature_name === "CLASSIFICATION" ? (
                                <MdOutlineDashboardCustomize />
                              ) : list.feature_name === "EVENTS" ||
                                list.feature_name === "DEPARTMENTS" ||
                                list.feature_name === "HOLIDAYS" ? (
                                <MdEvent />
                              ) : list.feature_name === "APPOINTMENTS" ? (
                                <FaRegCalendarCheck size={28} />
                              ) : (
                                <AiOutlineHome />
                              )}
                            </IconContext.Provider>
                          </div>
                          <p
                            className={
                              selectedIndex === i
                                ? "sidebar__title--scaled"
                                : "sidebar__title"
                            }
                            style={{
                              color:
                                selectedIndex === i
                                  ? "rgba(0, 88, 255, 1)"
                                  : "rgba(23, 23, 37, .7)",
                            }}
                          >
                            {list.feature_name === "LABS"
                              ? "Hospitals"
                              : list.feature_name
                                  ?.toLowerCase()
                                  ?.replace(/./, (name) => name.toUpperCase())}
                          </p>
                        </ListItem>
                      )
                    );
                  })}
                {screens?.some(
                  (screen) =>
                    screen.feature_name === "EVENTS" ||
                    screen.feature_name === "DEPARTMENTS" ||
                    screen.feature_name === "HOLIDAYS"
                ) && (
                  <ListItem
                    button
                    onClick={(e) => {
                      setCollapseOpen((prev) => !prev);
                      setLocations("events");
                      handleListItemClick(
                        e,
                        Number(screens.length + 1),
                        "events"
                      );
                    }}
                    selected={
                      Number(selectedIndex) === Number(screens.length + 1)
                    }
                    className={classes.itemContainer}
                    style={{
                      backgroundColor:
                        Number(selectedIndex) === Number(screens.length + 1)
                          ? "rgba(0, 98, 255, .10)"
                          : "#fff",
                      borderLeft:
                        Number(selectedIndex) === Number(screens.length + 1)
                          ? "3px solid rgba(0, 88, 255, 1)"
                          : "3px solid #fff",
                    }}
                  >
                    <div className="sidebar__icons">
                      <IconContext.Provider
                        value={{
                          color:
                            Number(selectedIndex) === Number(screens.length + 1)
                              ? "rgba(0, 88, 255, 1)"
                              : "#777",
                          size: "30px",
                        }}
                      >
                        <LuLayoutDashboard />
                      </IconContext.Provider>
                    </div>
                    <p
                      className={
                        Number(selectedIndex) === Number(screens.length + 1)
                          ? "sidebar__title--scaled"
                          : "sidebar__title"
                      }
                      style={{
                        color:
                          Number(selectedIndex) === Number(screens.length + 1)
                            ? "rgba(0, 88, 255, 1)"
                            : "rgba(23, 23, 37, .7)",
                      }}
                    >
                      Master
                    </p>
                    <div
                      className="collapse__icon d-flex justify-content-end w-100"
                      style={{
                        paddingRight: "10px",
                      }}
                    >
                      {collapseOpen ? (
                        <IoIosArrowUp size={20} />
                      ) : (
                        <IoIosArrowDown size={20} />
                      )}
                    </div>
                  </ListItem>
                )}
                <Collapse
                  in={
                    collapseOpen ||
                    Number(selectedIndex) === Number(screens.length + 1)
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {screens?.length &&
                      screens.map((list, i) => {
                        return (
                          (list.feature_name === "EVENTS" ||
                            list.feature_name === "DEPARTMENTS" ||
                            list.feature_name === "HOLIDAYS") && (
                            <ListItem
                              key={i}
                              button
                              component={NavLink}
                              onClick={(e) => {
                                setLocations(list.feature_name?.toLowerCase());
                                handleListItemClick(
                                  e,
                                  Number(screens.length + 1),
                                  list.feature_name?.toLowerCase()
                                );
                              }}
                              to={`/admin/${list.feature_name?.toLowerCase()}`}
                              selected={
                                currentLocation ===
                                list.feature_name?.toLowerCase()
                              }
                              className={classes.itemContainer}
                              style={{
                                backgroundColor:
                                  currentLocation ===
                                  list.feature_name?.toLowerCase()
                                    ? "rgba(0, 98, 255, .10)"
                                    : "#fff",
                                borderLeft:
                                  currentLocation ===
                                  list.feature_name?.toLowerCase()
                                    ? "3px solid rgba(0, 88, 255, 1)"
                                    : "3px solid #fff",
                              }}
                            >
                              <div className="sidebar__icons">
                                <IconContext.Provider
                                  value={{
                                    color:
                                      currentLocation ===
                                      list.feature_name?.toLowerCase()
                                        ? "rgba(0, 88, 255, 1)"
                                        : "#777",
                                    size: "30px",
                                  }}
                                >
                                  {list.feature_name === "EVENTS" ||
                                  list.feature_name === "DEPARTMENTS" ||
                                  list.feature_name === "HOLIDAYS" ? (
                                    <MdEvent />
                                  ) : (
                                    <AiOutlineHome />
                                  )}
                                </IconContext.Provider>
                              </div>
                              <p
                                className={
                                  currentLocation ===
                                  list.feature_name?.toLowerCase()
                                    ? "sidebar__title--scaled"
                                    : "sidebar__title"
                                }
                                style={{
                                  color:
                                    currentLocation ===
                                    list.feature_name?.toLowerCase()
                                      ? "rgba(0, 88, 255, 1)"
                                      : "rgba(23, 23, 37, .7)",
                                }}
                              >
                                {list.feature_name
                                  ?.toLowerCase()
                                  ?.replace(/./, (name) => name.toUpperCase())}
                              </p>
                            </ListItem>
                          )
                        );
                      })}
                  </List>
                </Collapse>

                <ListItem
                  button
                  component={NavLink}
                  selected={
                    Number(selectedIndex) === Number(screens.length + 2)
                  }
                  to={`/admin/settings`}
                  className={classes.itemContainer}
                  style={{
                    backgroundColor:
                      Number(selectedIndex) === Number(screens.length + 2)
                        ? "rgba(0, 98, 255, .10)"
                        : "#fff",
                    borderLeft:
                      Number(selectedIndex) === Number(screens.length + 2)
                        ? "3px solid rgba(0, 88, 255, 1)"
                        : "3px solid #fff",
                  }}
                  onClick={(event) => {
                    handleListItemClick(event, Number(screens.length + 2));
                  }}
                >
                  <div className="sidebar__icons">
                    <IconContext.Provider
                      value={{
                        color:
                          Number(selectedIndex) === Number(screens.length + 2)
                            ? "rgba(0, 88, 255, 1)"
                            : "#777",
                        size: "30px",
                      }}
                    >
                      <RiSettings2Line />
                    </IconContext.Provider>
                  </div>
                  <p
                    className={
                      Number(selectedIndex) === Number(screens.length + 2)
                        ? "sidebar__title--scaled"
                        : "sidebar__title"
                    }
                    style={{
                      color:
                        Number(selectedIndex) === Number(screens.length + 2)
                          ? "rgba(0, 88, 255, 1)"
                          : "rgba(23, 23, 37, .7)",
                    }}
                  >
                    Settings
                  </p>
                </ListItem>
                <Button
                  variant="contained"
                  color="secondary"
                  className="logout__btn"
                  type="submit"
                  onClick={() => setLogoutModal(true)}
                  style={{ marginTop: "15px" }}
                  startIcon={
                    <IconContext.Provider
                      value={{
                        color: "#777",
                        size: "30px",
                      }}
                    >
                      <RiLogoutBoxRLine />
                    </IconContext.Provider>
                  }
                >
                  <p className="sidebar__title">Log out</p>
                </Button>
              </List>
            )}
          </div>
        </section>

        <section
          className={
            minimizeSidebar ? "safe__container" : "safe__container--mini"
          }
        >
          <Switch>
            {screens &&
              screens.map((list, i) => {
                console.log({
                  screens,
                  selectedIndex,
                  path: `${path}/${
                    selectedIndex === screens?.length + 1
                      ? currentLocation
                      : screens?.[
                          selectedIndex <= screens?.length + 2
                            ? selectedIndex
                            : 0
                        ]?.feature_name?.toLowerCase()
                  }`,
                });

                return (
                  <Route
                    exact
                    key={i}
                    path={`${path}/${list.feature_name?.toLowerCase()}`}
                  >
                    {list.feature_name === "DASHBOARD" ? (
                      <Home />
                    ) : list.feature_name === "TESTS" ? (
                      <Tests />
                    ) : list.feature_name === "PACKAGES" ? (
                      <Packages />
                    ) : list.feature_name === "LABS" ? (
                      <Labs />
                    ) : list.feature_name === "ROLES" ? (
                      <Roles />
                    ) : list.feature_name === "REPORTS" ? (
                      <Reports />
                    ) : list.feature_name === "INVOICES" ? (
                      <Invoices />
                    ) : list.feature_name === "MEMBERS" ? (
                      <Members />
                    ) : list.feature_name === "DOCTORS" ? (
                      <Doctors />
                    ) : list.feature_name === "CLASSIFICATION" ? (
                      <Classifications />
                    ) : list.feature_name === "EVENTS" ? (
                      <Events />
                    ) : list.feature_name === "DEPARTMENTS" ? (
                      <Departments />
                    ) : list.feature_name === "HOLIDAYS" ? (
                      <Holidays />
                    ) : list.feature_name === "APPOINTMENTS" ? (
                      <Appointments />
                    ) : (
                      list.feature_name === "USERS" && <Users />
                    )}
                  </Route>
                );
              })}

            <Route path={`${path}/invoice-view`}>
              <InvoiceView />
            </Route>
            {/* <Route path={`${path}/doctors`}>
              <Doctors />
            </Route> */}
            <Route path={`${path}/doctor-view`}>
              <DoctorView />
            </Route>

            <Route path={`${path}/test-view`}>
              <TestView />
            </Route>
            <Route path={`${path}/package-view`}>
              <PackageView />
            </Route>
            <Route path={`${path}/lab-view`}>
              <LabView />
            </Route>
            <Route path={`${path}/report-view`}>
              <ReportView />
            </Route>
            <Route path={`${path}/user-view`}>
              <UserView />
            </Route>
            <Route path={`${path}/user-reportView`}>
              <UserReportView />
            </Route>
            <Route path={`${path}/role-update`}>
              <RoleEdit />
            </Route>
            <Route path={`${path}/system-feature`}>
              <SystemFeature refresh={setRefresh} />
            </Route>

            <Route path={`${path}/settings`}>
              <Settings />
            </Route>
            <Redirect
              from={path}
              to={`${path}/${
                selectedIndex === screens?.length + 1
                  ? currentLocation
                  : screens?.[
                      selectedIndex <= screens?.length + 2 ? selectedIndex : 0
                    ]?.feature_name?.toLowerCase()
              }`}
            />
          </Switch>
        </section>
      </main>
    </Router>
  );
};

export default Sidebar;
