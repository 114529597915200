import { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router";
import { FiArrowLeft } from "react-icons/fi";
import { IconContext } from "react-icons";
import { IconButton, Button, Tooltip } from "@material-ui/core";
import { FieldArray, Formik, use } from "formik";
import * as yup from "yup";
import _ from "lodash";
import Global from "../global";
import Select from "react-select";
// import API from "../utills/api";
import Loader from "react-loader-spinner";
import axios from "axios";
import emptyTest from "../assets/images/empty-test.png";
import emptyPackage from "../assets/images/empty-package.png";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { toast, ToastContainer } from "react-toastify";
import { IoMdAddCircle } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import { Modal } from "react-bootstrap";
import DeleteModal from "./DeleteModal";
import {
  MuiPickersUtilsProvider,
  //   KeyboardTimePicker,
  //   KeyboardDatePicker,
  DatePicker,
  //   KeyboardDateTimePicker,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { hoursData } from "../utils/hours";
import "../assets/css/doctors.css";
import { CgClose, CgMathPlus } from "react-icons/cg";
import { AiOutlineDelete } from "react-icons/ai";
import global from "../global";

const statusOptions = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

const genderOptions = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  { value: "Other", label: "Other" },
];

const appointments = {
  SUN: [],
  MON: {
    checked: true,
    data: [
      {
        start_time: "",
        end_time: "",
        event_id: null,
      },
    ],
  },
  TUE: {
    checked: true,
    data: [
      {
        start_time: "",
        end_time: "",
        event_id: null,
      },
    ],
  },
  WED: {
    checked: true,
    data: [
      {
        start_time: "",
        end_time: "",
        event_id: null,
      },
    ],
  },
  THU: {
    checked: true,
    data: [
      {
        start_time: "",
        end_time: "",
        event_id: null,
      },
    ],
  },
  FRI: {
    checked: true,
    data: [
      {
        start_time: "",
        end_time: "",
        event_id: null,
      },
    ],
  },
  SAT: [],
};

const mappedAppointments = Object.entries(appointments).map(
  ([day, appointmentsArray]) => ({
    day,
    appointments: appointmentsArray,
  })
);

//add Labs
function AddHospital({
  editable,
  id,
  data,
  redirectToHolidays,
  setRedirectToHolidays,
  hospitalsOptions,
  departmentOptions,
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };

  const [selectedHospitals, setSelectedHospitals] = useState([]);

  useEffect(() => {
    if (id) {
      const hospitalData = data?.hospitals?.split(",")?.map(function (num) {
        return parseInt(num.trim());
      });

      const fiteredHospitals = hospitalsOptions.filter((item) => {
        return hospitalData?.includes(item.value);
      });
      setSelectedHospitals(fiteredHospitals);
    }
  }, [id]);
  const [selectedDepartments, setselectedDepartments] = useState([]);

  useEffect(() => {
    if (id) {
      const departmentData = data?.departments?.split(",")?.map(function (num) {
        return parseInt(num.trim());
      });

      const filteredDepartments = departmentOptions.filter((item) => {
        return departmentData?.includes(item.value);
      });
      setselectedDepartments(filteredDepartments);
    }
  }, [id]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const required = <span style={{ color: "red" }}>*</span>;

  const history = useHistory();

  const initialValues = editable
    ? {
        user_id: data?.doctor_id,
        firstname: data?.first_name,
        lastname: data?.last_name,
        email: data?.email,
        gender: {
          label: `${data?.gender}`,
          value: data?.gender,
        },
        phone: data?.contact_number,
        qualification: data?.qualification,
        departments:selectedDepartments,
        hospitals: selectedHospitals,
        dob: data?.dob
          ? moment(data?.dob, "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'").format(
              "YYYY-MM-DD"
            )
          : null,
        status: {
          label: data?.status ? "Active" : "Inactive",
          value: data?.status,
        },
      }
    : {
        firstname: "",
        lastname: "",
        email: "",
        gender: null,
        dob: null,
        phone: "",
        qualification: "",
        hospitals: [],
        departments: [],
      };

  return (
    <>
      <div
        className="bg-white pt-3 px-2 px-xl-5 px-lg-3"
        style={{ paddingBottom: "30px" }}
      >
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={yup.object().shape({
            firstname: yup.string().required("Firstname cannot be empty"),
            lastname: yup.string().required("Lastname cannot be empty"),
            gender: yup.object().required("Please select gender").nullable(),
            // email: yup
            //   .string()
            //   .email("Invalid Email")
            //   .required("Email cannot be empty")
            //   .nullable(),
            // dob: yup.date().required("DOB is required").nullable(),
            // phone: yup.string().phone().required("Phone cannot be empty"),
            qualification: yup
              .string()
              .required("Qualification cannot be empty"),
          })}
          onSubmit={async (values, formik) => {
            const url = editable
              ? Global.base_url + "/doctor/update"
              : Global.base_url + "/doctor/add";

            var data = {
              first_name: values.firstname,
              last_name: values.lastname,
              gender: values.gender.value,
              email: values.email,
              dob: values.dob ? moment(values.dob).format("YYYY-MM-DD") : null,
              qualification: values.qualification,
              departments:
                typeof values.departments === "string"
                  ? values.departments
                  : values.departments
                  ? values.departments.map((item) => item.value).toString()
                  : "",
              hospitals:
                typeof values.hospitals === "string"
                  ? values.hospitals
                  : values.hospitals
                  ? values.hospitals.map((item) => item.value).toString()
                  : "",
              contact_number: values.phone,
            };
            if (editable) {
              data.doctor_id = id;
              data.status = values.status.value;
            }

            try {
              setSubmitLoading(true);
              const response = await axios.post(url, data, config2);
              // console.log(response);
              if (response.status === 200) {
                editable
                  ? toast.success("Doctor Updated Successfully")
                  : toast.success("Doctor Added Successfully");
                setSubmitLoading(false);
                setRedirectToHolidays(false);
                history.replace("/admin/doctors");
              }
            } catch (error) {
              if (error.response && error.response.status === 401) {
                editable
                  ? toast.error("Doctor Update failed!!!")
                  : toast.error("Doctor creation failed!!!");

                return setSubmitLoading(false);
              }
              if (error.response && error.response.status === 500) {
                toast.error("Internal server error");

                return setSubmitLoading(false);
              }
            }
          }}
        >
          {(formik) => {
            // console.log("formik errors", formik.errors);
            return (
              <>
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-xl-6 col-lg-6">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="first-name"
                          className="semiFont login__label"
                        >
                          First Name {required}
                        </label>
                        <input
                          type="text"
                          id="first-name"
                          placeholder="First Name"
                          // className="form-control signup__input"
                          value={formik.values.firstname}
                          style={{
                            border: formik.errors.firstname
                              ? "1px solid red"
                              : "1px solid #c3cad9",
                            marginTop: "5px",
                            width: "auto",
                          }}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "firstname",
                              e.currentTarget.value.replace(
                                /[^a-zA-Z0-9 ]/g,
                                ""
                              )
                            );
                          }}
                        />
                        {formik.errors.firstname && (
                          <div className="err">
                            <p>{formik.errors.firstname}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-xl-6 col-lg-6">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="last-name"
                          className="semiFont login__label"
                        >
                          Last Name {required}
                        </label>
                        <input
                          type="text"
                          // className="form-control "
                          id="last-name"
                          placeholder="Last Name"
                          value={formik.values.lastname}
                          style={{
                            border: formik.errors.lastname
                              ? "1px solid red"
                              : "1px solid #c3cad9",
                            marginTop: "5px",
                            width: "auto",
                          }}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "lastname",
                              e.currentTarget.value.replace(
                                /[^a-zA-Z0-9 ]/g,
                                ""
                              )
                            );
                          }}
                        />
                        {formik.errors.lastname && (
                          <div className="err">
                            <p>{formik.errors.lastname}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-xl-6 col-lg-6">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <div className="label__row d-flex align-items-center justify-content-between">
                          <label
                            htmlFor="Email"
                            className="semiFont login__label"
                          >
                            Email
                          </label>
                          {/* {editUser.index !== "" ? (
                            <p
                              className="member__status semiFont"
                              style={{
                                color: editUser.data.completed
                                  ? "#03A33B"
                                  : "#ffb100",
                              }}
                            >
                              {editUser.data.completed
                                ? "Activated"
                                : "Pending"}
                            </p>
                          ) : null} */}
                        </div>
                        <input
                          type="email"
                          id="Email"
                          placeholder="Email"
                          // className="form-control signup__input"
                          value={formik.values.email}
                          style={{
                            border: formik.errors.email
                              ? "1px solid red"
                              : "1px solid #c3cad9",
                            marginTop: "5px",
                            width: "auto",
                          }}
                          onChange={formik.handleChange("email")}
                        />
                        {/* {formik.errors.email && (
                          <div className="err">
                            <p>{formik.errors.email}</p>
                          </div>
                        )} */}
                      </div>
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="gender"
                          className="semiFont login__label"
                          style={{
                            marginBottom: "5px",
                          }}
                        >
                          Gender {required}
                        </label>
                        <Select
                          value={formik.values.gender}
                          onChange={(p) => {
                            formik.setFieldValue("gender", p);
                          }}
                          id="inputDropdown"
                          placeholder="Select Gender"
                          options={genderOptions}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              marginTop: "5px",
                              padding: "8px",
                              border: formik.errors.gender
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              "&:active": {
                                outline: "none",
                                boxShadow: "none",
                              },
                              "&:hover": {
                                outline: "none",
                                boxShadow: "none",
                              },
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isFocused
                                ? "#eee"
                                : "#fff",
                              padding: "8px",
                              display: "flex",
                              fontFamily: "semiFont",
                              alignItems: "center",
                              fontSize: "16px",
                              zIndex: 9999,
                              color: state.isFocused ? "#183b56" : "#183b56",
                              "&:hover": {
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                              },
                            }),
                          }}
                          className="admin__Addproduct--dropdown "
                        />
                        {formik.errors.gender && (
                          <div className="err">
                            <p>{formik.errors.gender}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-xl-6 col-lg-6">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="phone"
                          className="semiFont login__label"
                        >
                          Phone
                        </label>
                        <input
                          type="tel"
                          value={formik.values.phone}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "phone",
                              e.currentTarget.value.replace(/[^0-9]/g, "")
                            )
                          }
                          style={{
                            border: formik.errors.phone
                              ? "1px solid red"
                              : "1px solid #c3cad9",
                            marginTop: "5px",
                            width: "auto",
                          }}
                          id="phone"
                          aria-describedby="phone"
                          placeholder="Phone Number"
                        />
                        {/* {formik.errors.phone && (
                          <p className="err">{formik.errors.phone}</p>
                        )} */}
                      </div>
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label htmlFor="dob" className="semiFont login__label">
                          Date of Birth
                        </label>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            //   inputVariant="filled"
                            disableFuture
                            openTo="year"
                            //   className={classes.root}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            // minDate={moment("01-01-1920", "DD-MM-YYYY")}
                            // maxDate={moment("01-01-2010", "DD-MM-YYYY")}
                            format="DD/MM/YYYY"
                            views={["year", "month", "date"]}
                            value={formik.values.dob}
                            onChange={(date) =>
                              formik.setFieldValue("dob", date)
                            }
                            defaultValue={null}
                            style={{
                              border: formik.errors
                                ? formik.errors.dob
                                  ? "1px solid #dc3545"
                                  : "1px solid #c3cad9"
                                : "1px solid #c3cad9",
                              borderRadius: "8px",
                              marginTop: "5px",
                              padding: "2px",
                            }}
                            error={false}
                            helperText={null}
                            placeholder="DD/MM/YYYY"
                          />
                        </MuiPickersUtilsProvider>
                        {formik.errors.dob && (
                          <p className="err">{formik.errors.dob}</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-xl-6 col-lg-6">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="last-name"
                          className="semiFont login__label"
                        >
                          Qualification {required}
                        </label>
                        <input
                          type="text"
                          id="last-name"
                          placeholder="Qualification"
                          value={formik.values.qualification}
                          style={{
                            border: formik.errors.qualification
                              ? "1px solid red"
                              : "1px solid #c3cad9",
                            marginTop: "5px",
                            width: "auto",
                          }}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "qualification",
                              e.currentTarget.value.replace(
                                /[^a-zA-Z0-9 ]/g,
                                ""
                              )
                            );
                          }}
                        />
                        {formik.errors.qualification && (
                          <div className="err">
                            <p>{formik.errors.qualification}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-xl-6 col-lg-6">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="gender"
                          className="semiFont login__label"
                          style={{
                            marginBottom: "5px",
                          }}
                        >
                          Hospital
                        </label>
                        <Select
                          value={formik.values.hospitals}
                          onChange={(p) => {
                            formik.setFieldValue("hospitals", p);
                          }}
                          id="inputDropdown"
                          isMulti
                          placeholder="Select Hospital"
                          options={hospitalsOptions}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              marginTop: "5px",
                              padding: "8px",
                              border: formik.errors.hospitals
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              "&:active": {
                                outline: "none",
                                boxShadow: "none",
                              },
                              "&:hover": {
                                outline: "none",
                                boxShadow: "none",
                              },
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isFocused
                                ? "#eee"
                                : "#fff",
                              padding: "8px",
                              display: "flex",
                              fontFamily: "semiFont",
                              alignItems: "center",
                              fontSize: "16px",
                              zIndex: 9999,
                              color: state.isFocused ? "#183b56" : "#183b56",
                              "&:hover": {
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                              },
                            }),
                          }}
                          className="admin__Addproduct--dropdown "
                        />
                        {formik.errors.hospitals && (
                          <div className="err">
                            <p>{formik.errors.hospitals}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-xl-6 col-lg-6">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="departments"
                          className="semiFont login__label"
                          style={{
                            marginBottom: "5px",
                          }}
                        >
                          Department
                        </label>
                        <Select
                          value={formik.values.departments}
                          onChange={(p) => {
                            formik.setFieldValue("departments", p);
                          }}
                          id="inputDropdown"
                          isMulti
                          placeholder="Select Department"
                          options={departmentOptions}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              marginTop: "5px",
                              padding: "8px",
                              border: formik.errors.departments
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              "&:active": {
                                outline: "none",
                                boxShadow: "none",
                              },
                              "&:hover": {
                                outline: "none",
                                boxShadow: "none",
                              },
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isFocused
                                ? "#eee"
                                : "#fff",
                              padding: "8px",
                              display: "flex",
                              fontFamily: "semiFont",
                              alignItems: "center",
                              fontSize: "16px",
                              zIndex: 9999,
                              color: state.isFocused ? "#183b56" : "#183b56",
                              "&:hover": {
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                              },
                            }),
                          }}
                          className="admin__Addproduct--dropdown "
                        />
                        {formik.errors.departments && (
                          <div className="err">
                            <p>{formik.errors.departments}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  
                    <div className="col-12 col-xl-6 col-lg-6">
                      <div
                        className="inputBox"
                        style={{
                          position: "relative",
                          marginTop: 0,
                          marginBottom: "10px",
                        }}
                      >
                        <label
                          htmlFor="gender"
                          className="semiFont login__label"
                          style={{
                            marginBottom: "5px",
                          }}
                        >
                          Status{required}
                        </label>
                        <Select
                          value={formik.values.status}
                          onChange={(p) => {
                            formik.setFieldValue("status", p);
                          }}
                          id="inputDropdown"
                          placeholder="Select Status"
                          options={statusOptions}
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              marginTop: "5px",
                              padding: "8px",
                              border: formik.errors.status
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              "&:active": {
                                outline: "none",
                                boxShadow: "none",
                              },
                              "&:hover": {
                                outline: "none",
                                boxShadow: "none",
                              },
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isFocused
                                ? "#eee"
                                : "#fff",
                              padding: "8px",
                              display: "flex",
                              fontFamily: "semiFont",
                              alignItems: "center",
                              fontSize: "16px",
                              zIndex: 9999,
                              color: state.isFocused ? "#183b56" : "#183b56",
                              "&:hover": {
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                              },
                            }),
                          }}
                          className="admin__Addproduct--dropdown "
                        />
                        {formik.errors.status && (
                          <div className="err">
                            <p>{formik.errors.status}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  
                  </div>

                  
                  <div className="mt-2 d-flex flex-column flex-sm-row justify-content-end">
                    <div className="form-group">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={formik.handleSubmit}
                        disabled={submitLoading}
                        style={{
                          backgroundColor: "#1565d8",
                          color: "#fff",
                          padding: "10px 15px",
                          width: "150px",
                          textTransform: "initial",
                        }}
                      >
                        {submitLoading ? (
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : editable ? (
                          "Save"
                        ) : (
                          "Add"
                        )}
                      </Button>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

const EditLabs = ({
  editable,
  id,
  data,
  viewAccess,
  editAccess,
  eventOptions,
  availability,
  reference,
  viewHolidayAccess,
  editHolidayAccess,
  holidayList,
  selectedHolidays,
  setSelectedHolidays,
  setRefresh,
  redirectToHolidays,
  setRedirectToHolidays,
  hospitalsOptions,
  departmentOptions,
}) => {
  const history = useHistory();
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      "& .MuiTabs-indicator": {
        backgroundColor: "#1565d8",
        height: 3,
        top: "0px",
      },
      "& .MuiTab-root.Mui-selected": {
        color: "#1565d8",
        backgroundColor: "#fff",
      },
    },
  }));
  const classes = useStyles();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
  const [selectedTab, setSelectedTab] = useState(redirectToHolidays ? 2 : 0);
  const [availSubmitLoading, setAvailSubmitLoading] = useState(false);
  const [holidayButtonLoading, setHolidayButtonLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState({
    data: null,
  });

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const formikContext = reference.current;
    if (formikContext) {
      formikContext.setValues({
        ...formikContext.values,
        ...availability,
      });
    }
  }, [availability]);

  const initialValues = {
    SUN: {
      checked: false,
      data: [],
    },
    MON: {
      checked: false,
      data: [],
    },
    TUE: {
      checked: false,
      data: [],
    },
    WED: {
      checked: false,
      data: [],
    },
    THU: {
      checked: false,
      data: [],
    },
    FRI: {
      checked: false,
      data: [],
    },
    SAT: {
      checked: false,
      data: [],
    },
  };

  const eventSchema = yup.object({
    start_time: yup.object().required("Required").nullable(),
    end_time: yup.object().required("Required").nullable(),
    event_type: yup.object().required("Required").nullable(),
  });

  const validationSchema = yup.object().shape({
    SUN: yup.object({
      checked: yup.bool(),
      data: yup.array().when("checked", {
        is: true,
        then: yup
          .array()
          .of(eventSchema)
          .min(1, "At least one event must be filled"),
      }),
    }),
    MON: yup.object({
      checked: yup.bool(),
      data: yup.array().when("checked", {
        is: true,
        then: yup
          .array()
          .of(eventSchema)
          .min(1, "At least one event must be filled"),
      }),
    }),
    TUE: yup.object({
      checked: yup.bool(),
      data: yup.array().when("checked", {
        is: true,
        then: yup
          .array()
          .of(eventSchema)
          .min(1, "At least one event must be filled"),
      }),
    }),
    WED: yup.object({
      checked: yup.bool(),
      data: yup.array().when("checked", {
        is: true,
        then: yup
          .array()
          .of(eventSchema)
          .min(1, "At least one event must be filled"),
      }),
    }),
    THU: yup.object({
      checked: yup.bool(),
      data: yup.array().when("checked", {
        is: true,
        then: yup
          .array()
          .of(eventSchema)
          .min(1, "At least one event must be filled"),
      }),
    }),
    FRI: yup.object({
      checked: yup.bool(),
      data: yup.array().when("checked", {
        is: true,
        then: yup
          .array()
          .of(eventSchema)
          .min(1, "At least one event must be filled"),
      }),
    }),
    SAT: yup.object({
      checked: yup.bool(),
      data: yup.array().when("checked", {
        is: true,
        then: yup
          .array()
          .of(eventSchema)
          .min(1, "At least one event must be filled"),
      }),
    }),
  });

  const submitHolidays = async () => {
    try {
      setHolidayButtonLoading(true);
      const data = {
        doctor_id: id,
        holidays: selectedHolidays?.length ? selectedHolidays.toString() : "",
      };
      const submitHolidaysRes = await axios.post(
        global.base_url + `/doctor/holidays/modify`,
        data,
        config2
      );
      if (submitHolidaysRes.status === 200) {
        toast.success("Holidays added successfully");
        setHolidayButtonLoading(false);
        history.replace("/admin/doctors");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return toast.error("Unable to get holiday access");
        }
        if (error.response.status === 500) {
          return toast.error("Internal server error");
        }
        return setHolidayButtonLoading(false);
      }
    }
  };

  const Holidays = () => {
    return (
      <div className="bg-white p-3">
        <div className="holidays__container">
          <div className="holidays__header">
            <div
              className="d-flex align-items-center w-100"
              style={{ gap: "30px" }}
            >
              <p className="login__label semiFont">All holidays</p>
              <div className="d-flex align-items-center">
                <p className="login__label semiFont">Select All</p>
                <input
                  type="checkbox"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginTop: "3px",
                    marginLeft: "10px",
                  }}
                  // value={Number(item?.id)}
                  checked={holidayList?.length === selectedHolidays?.length}
                  onChange={(e) => {
                    const { checked, value } = e.target;
                    if (checked) {
                      setSelectedHolidays([
                        ...holidayList.map((item) => Number(item.id)),
                      ]);
                    } else {
                      setSelectedHolidays([]);
                    }
                  }}
                />
              </div>
            </div>
            <div className="w-100 d-flex align-items-center justify-content-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowAddModal(true)}
                style={{
                  backgroundColor: "#1565d8",
                  color: "#fff",
                  padding: "10px 15px",
                  textTransform: "initial",
                }}
              >
                Add holiday
              </Button>
            </div>
          </div>
          {holidayList?.length ? (
            <div className="holidays__list mt-2">
              {holidayList?.map((item, i) => {
                return (
                  <div className="holiday__item d-flex justify-content-between">
                    <div className="d-flex" style={{ gap: "10px" }}>
                      <input
                        type="checkbox"
                        style={{
                          height: "20px",
                          width: "20px",
                          marginTop: "3px",
                        }}
                        value={Number(item?.id)}
                        checked={
                          selectedHolidays?.length &&
                          selectedHolidays.includes(Number(item.id))
                        }
                        onChange={(e) => {
                          const { checked, value } = e.target;
                          if (checked) {
                            setSelectedHolidays([
                              ...selectedHolidays,
                              Number(value),
                            ]);
                          } else {
                            setSelectedHolidays(
                              selectedHolidays.filter(
                                (item) => Number(item) !== Number(value)
                              )
                            );
                          }
                        }}
                      />
                      <div>
                        <p className="mediumFont holiday__title">
                          {item?.name}
                        </p>
                        <p className="mediumFont holiday__date mt-1">
                          {moment(item?.holiday_date).format("D MMM YYYY")}
                        </p>
                      </div>
                    </div>
                    {item?.holiday_type === "custom" ? (
                      <Tooltip title={"Remove"} placement="top-center">
                        <IconButton
                          className="delete__IconButton"
                          onClick={() => {
                            setSelectedData({
                              data: item,
                            });
                            setShowDeleteModal(true);
                          }}
                        >
                          <IconContext.Provider
                            value={{
                              color: "#cc0000",
                              size: "20px",
                            }}
                          >
                            <AiOutlineDelete />
                          </IconContext.Provider>
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </div>
                );
              })}
            </div>
          ) : null}
          <div className="w-100 d-flex align-items-center mt-3 justify-content-start">
            <Button
              variant="contained"
              color="primary"
              onClick={() => submitHolidays()}
              disabled={holidayButtonLoading}
              style={{
                backgroundColor: "#1565d8",
                color: "#fff",
                padding: "10px 15px",
                width: "150px",
                textTransform: "initial",
              }}
            >
              {holidayButtonLoading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const holidayInitialValues = {
    name: "",
    holiday_date: null,
  };

  const AddHolidayModal = ({ initialValues, setRefresh, show, onHide }) => {
    const config2 = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const [submitLoader, setSubmitLoader] = useState(false);
    const required = <span style={{ color: "red" }}>*</span>;
    function closeModal() {
      setShowAddModal(false);
    }

    return (
      <Modal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="boldFont">Add Custom Holiday</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={yup.object().shape({
              name: yup.string().required("name is required"),
              holiday_date: yup.date().required("date is required").nullable(),
            })}
            onSubmit={async (values, formik) => {
              setSubmitLoader(true);

              const url = Global.base_url + "/doctor/holidays/custom/add";

              const data = {
                doctor_id: id,
                name: values.name,
                holiday_date: moment(values.holiday_date).format("YYYY-MM-DD"),
              };

              try {
                const response = await axios.post(url, data, config2);
                if (response.status === 200) {
                  setSubmitLoader(false);
                  closeModal();

                  toast.success("Holiday Added Successfully");
                  setRefresh(Date.now() + 1);
                  setRedirectToHolidays(true);
                  return setSelectedTab(2);
                }
              } catch (error) {
                if (error.response && error.response.status === 401) {
                  toast.error(error.response.data.msg);
                }
                if (error.response && error.response.status === 500) {
                  toast.error("Internal server error");
                }
                return setSubmitLoader(false);
              }
            }}
          >
            {(formik) => {
              return (
                <>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="holiday-name"
                            className="semiFont login__label"
                          >
                            Holiday Name {required}
                          </label>
                          <input
                            type="text"
                            placeholder="Event Name"
                            name="holiday-name"
                            // className="form-control signup__input"
                            value={formik.values.name}
                            style={{
                              border: formik.errors.name
                                ? "1px solid red"
                                : "1px solid #c3cad9",
                              marginTop: "5px",
                              width: "auto",
                            }}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "name",
                                e.currentTarget.value.replace(
                                  /[^a-zA-Z0-9 ]/g,
                                  ""
                                )
                              );
                            }}
                          />
                          {formik.errors.name && (
                            <div className="err">
                              <p>{formik.errors.name}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="last-name"
                            className="semiFont login__label"
                          >
                            Holiday Date {required}
                          </label>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                              //   inputVariant="filled"
                              disablePast
                              openTo="year"
                              //   className={classes.root}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              // minDate={moment("01-01-1920", "DD-MM-YYYY")}
                              // maxDate={moment("01-01-2010", "DD-MM-YYYY")}
                              format="DD/MM/YYYY"
                              views={["year", "month", "date"]}
                              value={formik.values.holiday_date}
                              onChange={(date) =>
                                formik.setFieldValue("holiday_date", date)
                              }
                              style={{
                                border: formik.errors
                                  ? formik.errors.holiday_date
                                    ? "1px solid #dc3545"
                                    : "1px solid #c3cad9"
                                  : "1px solid #c3cad9",
                                borderRadius: "8px",
                                marginTop: "5px",
                                padding: "2px",
                              }}
                              error={false}
                              helperText={null}
                              placeholder="DD/MM/YYYY"
                            />
                          </MuiPickersUtilsProvider>
                          {formik.errors.holiday_date && (
                            <div className="err">
                              <p>{formik.errors.holiday_date}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        "mt-3 d-flex flex-column flex-sm-row justify-content-end"
                      }
                    >
                      <div className="d-flex flex-sm-row addproduct__button--aligncenter">
                        <div className="form-group ">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={formik.handleSubmit}
                            disabled={!formik.dirty || submitLoader}
                            style={{
                              backgroundColor: !formik.dirty
                                ? "#777"
                                : "#1565d8",
                              color: "#fff",
                              padding: submitLoader ? "7px 40px" : "10px 15px",
                              // width: "150px",
                              textTransform: "initial",
                              marginRight: "10px",
                            }}
                          >
                            {submitLoader ? (
                              <div
                                className="spinner-border text-light"
                                // style={{}}
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Update/Save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <div>
      <section className="tabs__wrapper mt-2">
        <AddHolidayModal
          initialValues={holidayInitialValues}
          setRefresh={setRefresh}
          show={showAddModal}
          onHide={() => setShowAddModal(false)}
        />
        <DeleteModal
          show={showDeleteModal}
          onHide={() => {
            setShowDeleteModal(false);
            setRedirectToHolidays(true);
          }}
          selectedData={selectedData.data?.id}
          refresh={setRefresh}
          apiUrl={"/doctor/holidays/custom/delete"}
          name={"Holiday"}
          // id={"category_id"}
        />
        <div
          className={classes.root}
          classes={{ indicator: classes.indicator }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
          >
            <Tab label="Details" disableRipple={true} {...a11yProps(0)} />
            {/* {privilegeData &&
              _.some(privilegeData, ["privilege_name", "LAB TEST"]) ? ( */}
            {viewAccess ? (
              <Tab
                label="Availability"
                disableRipple={true}
                {...a11yProps(1)}
              />
            ) : null}

            {viewHolidayAccess ? (
              <Tab label="Holidays" disableRipple={true} {...a11yProps(2)} />
            ) : null}

            {/* ) : null} */}
          </Tabs>
        </div>

        <div className="tabItems__container">
          <TabPanel value={selectedTab} index={0}>
            <AddHospital
              editable={true}
              id={id}
              data={data}
              redirectToHolidays={redirectToHolidays}
              setRedirectToHolidays={setRedirectToHolidays}
              hospitalsOptions={hospitalsOptions}
              departmentOptions={departmentOptions}
            />
          </TabPanel>
          <Formik
            innerRef={reference}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, formik) => {
              const keyData = Object.keys(values);
              const checkData = keyData.filter((list) => values[list].checked);
              if (checkData.length) {
                setAvailSubmitLoading(true);
                const data = {
                  doctor_id: id,
                  availability: keyData.reduce((result, day) => {
                    const events = values[day].data
                      .filter(
                        (event) =>
                          event.start_time.value &&
                          event.end_time.value &&
                          event.event_type.value
                      )
                      .map((event) => ({
                        start_time: event.start_time.value,
                        end_time: event.end_time.value,
                        event_id: event.event_type.value,
                      }));

                    if (events.length > 0) {
                      result[day] = events;
                    }
                    return result;
                  }, {}),
                };
                try {
                  const availSubmit = await axios.post(
                    Global.base_url + "/doctor/availability/modify",
                    data,
                    config
                  );
                  if (availSubmit.status === 200) {
                    toast.success("Availability updated successfully");
                    setAvailSubmitLoading(false);
                    setRedirectToHolidays(false);
                    history.replace("/admin/doctors");
                  }
                } catch (error) {
                  if (error.response && error.response.status === 401) {
                    toast.error(
                      "You are not authorized to update availability"
                    );
                  }
                  if (error.response && error.response.status === 500) {
                    toast.error("Internal server error");
                  }
                  return setAvailSubmitLoading(false);
                }
              } else {
                toast.error("Please select atleast one availability");
                return;
              }
            }}
          >
            {(formik) => {
              return (
                <TabPanel value={selectedTab} index={1}>
                  <div className="bg-white p-3 appointments__booking">
                    {Object.entries(formik.values).map(
                      ([day, appointmentsArray], i) => {
                        return (
                          <div className="appointments__container" key={i}>
                            <FieldArray name={`${day}.data`}>
                              {(fieldarray) => {
                                const { push, remove, form } = fieldarray;
                                return (
                                  <>
                                    <div className="appointments__timeSection d-flex w-100">
                                      <div className="d-flex align-align-items-center appointments__book">
                                        <input
                                          type="checkbox"
                                          style={{
                                            height: "20px",
                                            width: "20px",
                                          }}
                                          disabled={!editAccess}
                                          checked={appointmentsArray.checked}
                                          onChange={(e) => {
                                            const { checked, value } = e.target;

                                            if (checked) {
                                              formik.setFieldValue(day, {
                                                checked,
                                                data: [
                                                  {
                                                    start_time: null,
                                                    end_time: null,
                                                    event_type: null,
                                                  },
                                                ],
                                              });
                                            } else {
                                              formik.setFieldValue(day, {
                                                checked,
                                                data: [],
                                              });
                                              formik.setFieldError(
                                                `${day}.data`,
                                                null
                                              );
                                            }
                                          }}
                                        />
                                        <p className="appointments__title semiFont">
                                          {day}
                                        </p>
                                      </div>
                                      {appointmentsArray.checked ? (
                                        <div className="appointments__time">
                                          {appointmentsArray.data &&
                                            appointmentsArray.data.map(
                                              (item, j) => {
                                                return (
                                                  <div
                                                    className="appoitment__items"
                                                    key={j}
                                                  >
                                                    <div
                                                      className="inputBox"
                                                      style={{
                                                        position: "relative",
                                                        marginTop: 0,
                                                      }}
                                                    >
                                                      <Select
                                                        value={item?.start_time}
                                                        onChange={(p) => {
                                                          formik.setFieldValue(
                                                            `${day}.data.${j}.start_time`,
                                                            p
                                                          );
                                                        }}
                                                        // id="inputDropdown"
                                                        //   isClearable={true}
                                                        isSearchable={true}
                                                        placeholder="Start time"
                                                        options={hoursData}
                                                        isDisabled={!editAccess}
                                                        styles={{
                                                          control: (
                                                            base,
                                                            state
                                                          ) => ({
                                                            ...base,
                                                            padding: "7px",
                                                            borderRadius: "8px",
                                                            border:
                                                              "1px solid #c3cad9",
                                                            "&:active": {
                                                              outline: "none",
                                                              boxShadow: "none",
                                                            },
                                                            "&:hover": {
                                                              outline: "none",
                                                              boxShadow: "none",
                                                            },
                                                          }),
                                                          option: (
                                                            base,
                                                            state
                                                          ) => ({
                                                            ...base,
                                                            backgroundColor:
                                                              state.isFocused
                                                                ? "#eee"
                                                                : "#fff",
                                                            padding: "8px",
                                                            display: "flex",
                                                            fontFamily:
                                                              "regularFont",
                                                            alignItems:
                                                              "center",
                                                            fontSize: "16px",
                                                            zIndex: 9999,
                                                            color:
                                                              state.isFocused
                                                                ? "#183b56"
                                                                : "#183b56",
                                                            "&:hover": {
                                                              backgroundColor:
                                                                state.isFocused
                                                                  ? "#eee"
                                                                  : "#fff",
                                                            },
                                                          }),
                                                        }}
                                                        className="admin__Addproduct--dropdown "
                                                      />

                                                      {formik.errors?.[day]
                                                        ?.data?.[j]
                                                        ?.start_time ? (
                                                        <p className="regular__error">
                                                          {
                                                            formik.errors?.[day]
                                                              ?.data?.[j]
                                                              ?.start_time
                                                          }
                                                        </p>
                                                      ) : null}
                                                    </div>
                                                    <div
                                                      className="inputBox"
                                                      style={{
                                                        position: "relative",
                                                        marginTop: 0,
                                                      }}
                                                    >
                                                      <Select
                                                        value={item?.end_time}
                                                        onChange={(p) => {
                                                          formik.setFieldValue(
                                                            `${day}.data.${j}.end_time`,
                                                            p
                                                          );
                                                        }}
                                                        // id="inputDropdown"
                                                        //   isClearable={true}
                                                        isSearchable={true}
                                                        placeholder="End time"
                                                        options={hoursData}
                                                        isDisabled={!editAccess}
                                                        styles={{
                                                          control: (
                                                            base,
                                                            state
                                                          ) => ({
                                                            ...base,
                                                            padding: "7px",
                                                            borderRadius: "8px",
                                                            border:
                                                              "1px solid #c3cad9",
                                                            "&:active": {
                                                              outline: "none",
                                                              boxShadow: "none",
                                                            },
                                                            "&:hover": {
                                                              outline: "none",
                                                              boxShadow: "none",
                                                            },
                                                          }),
                                                          option: (
                                                            base,
                                                            state
                                                          ) => ({
                                                            ...base,
                                                            backgroundColor:
                                                              state.isFocused
                                                                ? "#eee"
                                                                : "#fff",
                                                            padding: "8px",
                                                            display: "flex",
                                                            fontFamily:
                                                              "regularFont",
                                                            alignItems:
                                                              "center",
                                                            fontSize: "16px",
                                                            zIndex: 9999,
                                                            color:
                                                              state.isFocused
                                                                ? "#183b56"
                                                                : "#183b56",
                                                            "&:hover": {
                                                              backgroundColor:
                                                                state.isFocused
                                                                  ? "#eee"
                                                                  : "#fff",
                                                            },
                                                          }),
                                                        }}
                                                        className="admin__Addproduct--dropdown "
                                                      />

                                                      {formik.errors?.[day]
                                                        ?.data?.[j]
                                                        ?.end_time ? (
                                                        <p className="regular__error">
                                                          {
                                                            formik.errors?.[day]
                                                              ?.data?.[j]
                                                              ?.end_time
                                                          }
                                                        </p>
                                                      ) : null}
                                                    </div>
                                                    <div
                                                      // className="inputBox"
                                                      style={{
                                                        position: "relative",
                                                        marginTop: 0,
                                                      }}
                                                    >
                                                      <Select
                                                        value={item?.event_type}
                                                        onChange={(p) => {
                                                          formik.setFieldValue(
                                                            `${day}.data.${j}.event_type`,
                                                            p
                                                          );
                                                        }}
                                                        isDisabled={!editAccess}
                                                        id="eventsDropdown"
                                                        isSearchable={false}
                                                        isClearable={true}
                                                        placeholder="Select Event"
                                                        options={eventOptions}
                                                        styles={{
                                                          control: (
                                                            base,
                                                            state
                                                          ) => ({
                                                            ...base,
                                                            padding: "7px",
                                                            borderRadius: "8px",
                                                            border:
                                                              "1px solid #c3cad9",
                                                            "&:active": {
                                                              outline: "none",
                                                              boxShadow: "none",
                                                            },
                                                            "&:hover": {
                                                              outline: "none",
                                                              boxShadow: "none",
                                                            },
                                                          }),
                                                          option: (
                                                            base,
                                                            state
                                                          ) => ({
                                                            ...base,
                                                            backgroundColor:
                                                              state.isFocused
                                                                ? "#eee"
                                                                : "#fff",
                                                            padding: "8px",
                                                            display: "flex",
                                                            fontFamily:
                                                              "regularFont",
                                                            alignItems:
                                                              "center",
                                                            fontSize: "16px",
                                                            zIndex: 9999,
                                                            color:
                                                              state.isFocused
                                                                ? "#183b56"
                                                                : "#183b56",
                                                            "&:hover": {
                                                              backgroundColor:
                                                                state.isFocused
                                                                  ? "#eee"
                                                                  : "#fff",
                                                            },
                                                          }),
                                                        }}
                                                        className="admin__Addproduct--dropdown "
                                                      />
                                                      {formik.errors?.[day]
                                                        ?.data?.[j]
                                                        ?.event_type ? (
                                                        <p className="regular__error">
                                                          {
                                                            formik.errors?.[day]
                                                              ?.data?.[j]
                                                              ?.event_type
                                                          }
                                                        </p>
                                                      ) : null}
                                                    </div>

                                                    <IconButton
                                                      style={{
                                                        backgroundColor:
                                                          "#F2F2F2",
                                                      }}
                                                      disabled={!editAccess}
                                                      onClick={() => {
                                                        if (
                                                          appointmentsArray.data
                                                            .length === 1
                                                        ) {
                                                          remove(j);
                                                          formik.setFieldValue(
                                                            `${day}.checked`,
                                                            false
                                                          );
                                                          formik.setFieldError(
                                                            `${day}.data`,
                                                            null
                                                          );
                                                        } else {
                                                          remove(j);
                                                          // formik.setFieldError(
                                                          //   `${day}?.data?.${j}`,
                                                          //   null
                                                          // );
                                                        }
                                                      }}
                                                    >
                                                      {/* <IconContext.Provider
                                                        value={{
                                                          color: "#cc0000",
                                                          //   size: "20px",
                                                        }}
                                                      > */}
                                                      <CgClose
                                                        size={20}
                                                        color="#000"
                                                      />
                                                      {/* </IconContext.Provider> */}
                                                    </IconButton>
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                      ) : (
                                        <p className="regularFont appointments__unavailable">
                                          Unavailable
                                        </p>
                                      )}
                                    </div>
                                    <div className="appointments__addSection">
                                      <IconButton
                                        style={{
                                          backgroundColor: "#F2F2F2",
                                        }}
                                        disabled={!editAccess}
                                        onClick={() => {
                                          if (appointmentsArray.data.length) {
                                            push({
                                              start_time: null,
                                              end_time: null,
                                              event_type: null,
                                            });
                                          } else {
                                            formik.setFieldValue(day, {
                                              checked: true,
                                              data: [
                                                {
                                                  start_time: null,
                                                  end_time: null,
                                                  event_type: null,
                                                },
                                              ],
                                            });
                                          }
                                        }}
                                      >
                                        <CgMathPlus size={20} color="#000" />
                                      </IconButton>
                                    </div>
                                  </>
                                );
                              }}
                            </FieldArray>
                          </div>
                        );
                      }
                    )}
                    {editAccess ? (
                      <div className="mt-2 d-flex flex-column flex-sm-row justify-content-end">
                        <div className="form-group">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={formik.handleSubmit}
                            disabled={availSubmitLoading}
                            style={{
                              backgroundColor: "#1565d8",
                              color: "#fff",
                              padding: "10px 15px",
                              width: "150px",
                              textTransform: "initial",
                            }}
                          >
                            {availSubmitLoading ? (
                              <div
                                className="spinner-border text-light"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </TabPanel>
              );
            }}
          </Formik>
          <TabPanel value={selectedTab} index={2}>
            <Holidays />
          </TabPanel>
        </div>
      </section>
    </div>
  );
};

export default function LabView(props) {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const editable = location?.state?.editable || false;
  const [doctorsData, setDoctorsData] = useState(null);
  const [eventOptions, setEventOptions] = useState([]);
  const [availability, setAvailablity] = useState(null);
  const availFormRef = useRef(null);
  const [holidayList, setHolidayList] = useState([]);
  const [selectedHolidays, setSelectedHolidays] = useState([]);
  const [refresh, setRefresh] = useState("");
  const [redirectToHolidays, setRedirectToHolidays] = useState(false);
  const [hospitalsOptions, setHospitalOptions] = useState([]);
  const [departmentOptions, setDepartmentsOptions] = useState([]);
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const id = editable ? location.state?.data?.doctor_id : null;

  const userAccess = editable ? location?.state?.userAccess : null;

  const viewAccess = _.some(userAccess?.privileges, [
    "privilege_name",
    "VIEW AVAILABILITY",
  ]);
  const viewHolidayAccess = _.some(userAccess?.privileges, [
    "privilege_name",
    "VIEW HOLIDAYS",
  ]);

  const editAccess = _.some(userAccess?.privileges, [
    "privilege_name",
    "EDIT AVAILABILITY",
  ]);
  const editHolidayAccess = _.some(userAccess?.privileges, [
    "privilege_name",
    "EDIT HOLIDAYS",
  ]);

  useEffect(() => {
    if (editable) {
      getDoctorsById(id);
      if (viewAccess) {
        getEvents(id);
        // getAvailabilityById(id);
      }
      if (viewHolidayAccess) {
        getHolidayList(id);
        // getDoctorHolidayList(id);
      }
    }
    getHospitalList();
    getDepartmentList();
  }, [refresh]);

  const getHospitalList = async () => {
    try {
      const hospitalsRes = await axios.get(
        Global.base_url + "/lab/hospitals",
        config
      );
      if (hospitalsRes.status === 200) {
        const modifiedList = hospitalsRes.data?.data?.map((item) => {
          return {
            label: item?.lab_name,
            value: item?.lab_id,
          };
        });
        setHospitalOptions(modifiedList);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return toast.error("Unable to get hospitals list");
        }
        if (error.response.status === 500) {
          return toast.error("Internal server error");
        }
      }
    }
  };
// Department List

const getDepartmentList = async () => {
  try {
    const departmentsRes = await axios.get(
      Global.base_url + "/departments",
      config
    );
    if (departmentsRes.status === 200) {
      const modifiedList = departmentsRes.data?.data?.map((item) => {
        return {
          label: item?.name,
          value: item?.department_id,
        };
      });
      setDepartmentsOptions(modifiedList);
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return toast.error("Unable to get departments list");
      }
      if (error.response.status === 500) {
        return toast.error("Internal server error");
      }
    }
  }
};
  const getDoctorsById = async (id) => {
    try {
      setLoading(true);
      const response = await axios.post(
        Global.base_url + "/doctor/editById",
        { doctor_id: id },
        config
      );

      if (response.status === 200) {
        setDoctorsData(response.data?.data[0]);
      }
      setLoading(false);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return toast.error("Unable to get doctor access");
        }
        if (error.response.status === 500) {
          return toast.error("Internal server error");
        }
        return setLoading(false);
      }
    }
  };

  const getEvents = async () => {
    try {
      const response = await axios.get(Global.base_url + `/events`, config);

      if (response.status === 200) {
        const modifiedData = response.data?.data.map((event) => {
          return {
            label: event?.name,
            value: event?.event_id,
          };
        });
        setEventOptions(modifiedData);
        const availResponse = await axios.get(
          Global.base_url + `/doctor/availability?doctor_id=${id}`,
          config
        );

        if (availResponse.status === 200) {
          const data = Object.keys(
            availResponse.data?.data?.availability
          ).reduce((result, day) => {
            const events = availResponse.data?.data?.availability[day].map(
              (event) => ({
                start_time: {
                  label: event.start_time,
                  value: event.start_time,
                },
                end_time: {
                  label: event.end_time,
                  value: event.end_time,
                },
                event_type: {
                  label: modifiedData.filter(
                    (list) => list.value === event.event_id
                  )?.[0]?.label,
                  value: event.event_id,
                },
              })
            );
            if (events.length > 0) {
              result[day] = {
                checked: true,
                data: events,
              };
            }
            return result;
          }, {});
          setAvailablity(data);
        }
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return toast.error("Unable to get doctor availability access");
        }
        if (error.response.status === 500) {
          return toast.error("Internal server error");
        }
      }
    }
  };
  const getHolidayList = async (id) => {
    try {
      const response = await axios.get(
        Global.base_url + `/holidays?doctor_id=${id}`,
        config
      );

      if (response.status === 200) {
        setHolidayList(response.data?.data);
        const doctorHolidayResponse = await axios.get(
          Global.base_url + `/doctor/holidays?doctor_id=${id}`,
          config
        );

        if (doctorHolidayResponse.status === 200) {
          const modifiedData = doctorHolidayResponse.data?.data?.holidays
            ? doctorHolidayResponse.data?.data?.holidays
                ?.split(",")
                ?.map(function (num) {
                  return parseInt(num.trim());
                })
            : [];
          setSelectedHolidays(modifiedData);
        }
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return toast.error("Unable to get holiday list");
        }
        if (error.response.status === 500) {
          return toast.error("Internal server error");
        }
      }
    }
  };

  return (
    <div>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <>
          <div
            className="col-12 bg-white align-items-center pt-3 pb-3"
            style={{
              borderBottom: "1px solid #B3CDFF",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                history.replace("/admin/doctors");
              }}
            >
              <IconContext.Provider value={{ size: "30px" }}>
                <FiArrowLeft />
              </IconContext.Provider>
            </IconButton>
            <p
              style={{
                cursor: "pointer",
                fontSize: "25px",
                marginLeft: "5px",
              }}
              className="semiFont"
            >
              {editable
                ? `Doctor ID : ${location.state.data.doctor_id}`
                : "Add Doctor"}
            </p>
          </div>

          {editable ? (
            <EditLabs
              editable={editable}
              id={id}
              data={doctorsData}
              viewAccess={viewAccess}
              editAccess={editAccess}
              eventOptions={eventOptions}
              availability={availability}
              reference={availFormRef}
              viewHolidayAccess={viewHolidayAccess}
              editHolidayAccess={editHolidayAccess}
              holidayList={holidayList}
              selectedHolidays={selectedHolidays}
              setSelectedHolidays={setSelectedHolidays}
              setRefresh={setRefresh}
              redirectToHolidays={redirectToHolidays}
              setRedirectToHolidays={setRedirectToHolidays}
              hospitalsOptions={hospitalsOptions}
              departmentOptions={departmentOptions}
              
            />
          ) : (
            <AddHospital
              editable={editable}
              id={null}
              data={null}
              redirectToHolidays={redirectToHolidays}
              setRedirectToHolidays={setRedirectToHolidays}
              hospitalsOptions={hospitalsOptions}
              departmentOptions={departmentOptions}
            />
          )}
        </>
      )}
    </div>
  );
}
