import { Button, IconButton, Tooltip } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/products.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { PiDownloadSimpleLight } from "react-icons/pi";

import { BiEditAlt } from "react-icons/bi";
import { MdLocalHospital } from "react-icons/md";
import TablePagination from "@material-ui/core/TablePagination";
import Global from "../global";
// import { IoSearchOutline } from "react-icons/io5";
import { IoMdAddCircle } from "react-icons/io";
// import { AiOutlineCloudDownload } from "react-icons/ai";
import { MdAdd } from "react-icons/md";

import { IconContext } from "react-icons";
import Select from "react-select";
// import { MdKeyboardBackspace } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
// import API from "../utills/api";
import Loader from "react-loader-spinner";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import emptyUser from "../assets/images/empty-user.png";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router";
import { BsSliders } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import DeleteModal from "./DeleteModal";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as XLSX from "xlsx";
import ExportIcon from "../assets/images/export-icon.png"
import LoadingGif from "../assets/images/loading.gif"


import {
  MuiPickersUtilsProvider,
  //   KeyboardTimePicker,
  //   KeyboardDatePicker,
  DatePicker,
  //   KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { Download } from "@mui/icons-material";
const statusOptions = [
  { value: 1, label: "Scheduled" },
  { value: 2, label: "Rescheduled" },
  { value: 3, label: "Cancelled" },
  { value: 4, label: "Completed" },
];

const durationTypeOptions = [
  {
    value: "hrs",
    label: "hrs",
  },
  {
    value: "min",
    label: "min",
  },
];

// add button icon alignment design
const useStyles = makeStyles({
  rootAbsolute: {
    position: "relative",
    fontFamily: "BoldFont",

    "& .MuiButton-startIcon": {
      position: "absolute",
      right: 0,
    },
    "& .MuiButton-label": {
      marginRight: 10,
      transition: "all 0.3s",
    },
  },
});

const Appointments = (props) => {
  const tableRef = useRef(null);
  const history = useHistory();
  const classes = useStyles();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const config2 = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };

  // console.log("AdminProduct", props);
  //   const [period, setPeriod] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userAccess, setUserAccess] = useState({});
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState("");
  const [slotsOptions, setSlotsOptions] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);

  console.log("appointments", appointments);
  const [editUser, setEdit] = useState({
    data: {},
    index: "",
  });

  // const exportToExcel = () => {
  //   // Map the appointments data to include only the desired fields
  //   const filteredData = appointments.map((appointment) => ({
  //     "Appointment Id": appointment.appointment_id,
  //     "Patient Id": appointment.patient_id,
  //     "Patient Name": `${appointment.firstname} ${appointment.lastname}`, // Combine first and last name
  //     gender: appointment.gender,
  //     "Mobile Number": appointment.mobile,
  //     Email: appointment.email,
  //     Relationship: appointment.relationship,
  //     "Doctor Name": `${appointment.doctor_firstname} ${appointment.doctor_lastname}`,
  //     "Appointment Date": new Date(
  //       appointment.appointment_date
  //     ).toLocaleDateString(), // Format the date
  //     Slot: appointment.slot,
  //     Hospital: appointment.lab_name,
  //     Status: `${
  //       appointment.appointment_status === 1
  //         ? "Scheduled"
  //         : appointment.appointment_status === 2
  //         ? "Rescheduled"
  //         : appointment.appointment_status === 3
  //         ? "Cancelled"
  //         : "Completed"
  //     }`,
  //     "Created At": new Date(appointment.created_at).toLocaleDateString(),
  //   }));

  //   // Convert the filtered data to a worksheet
  //   const worksheet = XLSX.utils.json_to_sheet(filteredData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Appointments");

  //   // Export to Excel file
  //   XLSX.writeFile(workbook, "appointments.xlsx");
  // };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [anchorEl, setAnchorEl] = useState(null);
  const [filterData, setFilterData] = useState({});
  const [paginationData, setPaginationData] = useState({});

  const filterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const filterClose = () => {
    setAnchorEl(null);
  };


  console.log('paginationData', paginationData)
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // table pagination handlechange for number of pages
  const handleChangePage = (event, newPage) => {
    getAppointments(newPage, rowsPerPage);
  };

  // number of rows handle change for items display
  const handleChangeRowsPerPage = async (event) => {
    getAppointments(page, event.target.value);
  };

  useEffect(() => {
    const submitStatus =
      selectedStatus &&
      selectedStatus.map((item) => {
        return item === "Scheduled"
          ? 1
          : item === "Rescheduled"
          ? 2
          : item === "Cancelled"
          ? 3
          : 4;
      });
    async function fetchAppointments() {
      try {
        const checkUser = await axios.post(
          Global.base_url + "/appointments/check/privileges",
          null,
          config
        );

        if (checkUser.status === 200) {
          setUserAccess(checkUser.data);
          if (
            _.some(checkUser.data.privileges, [
              "privilege_name",
              "VIEW APPOINTMENTS",
            ])
          ) {
            try {
              const response = await axios.post(
                Global.base_url + "/appointments",
                {
                  currentPage: page + 1,
                  pageSize: rowsPerPage,
                  search: search,
                  status: submitStatus.length ? submitStatus : [],
                },
                config
              );

              if (response.status === 200) {
                setAppointments(response.data.data);
                
                let sortedStatus = Object.keys(response.data.filter.status).map(
                  (item) => {
                    return {
                      name: item,
                    };
                  }
                );
                setFilterData({
                  status: sortedStatus,
                });
                setPaginationData(response.data.pagination);
                setPage(response.data.pagination.currentPage - 1);
                setRowsPerPage(response.data.pagination.pageSize);
                return setLoading(false);
              }
            } catch (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  return toast.error(error.response.data.msg);
                }
                if (error.response.status === 500) {
                  return toast.error("Internal server error");
                }
              }
              return setLoading(false);
            }
          }
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            return toast.error("Unable to get appointments access");
          }
          if (error.response.status === 500) {
            return toast.error("Internal server error");
          }
        }
        return setLoading(false);
      }
    }
    fetchAppointments();
  }, [refresh]);




  useEffect(() => {
    if (editUser.index !== "") {
      getSlots();
    }
  }, [editUser]);

  const getSlots = async () => {
    try {
      const data = {
        doctor_id: editUser.data.doctor_id,
        event_id: editUser.data.event_id,
        date: moment(editUser.data.appointment_date).format("YYYY-MM-DD"),
      };
      const slotsResponse = await axios.post(
        Global.appointment_base_url + "/booking/slots",
        data,
        config2
      );
      if (slotsResponse.status === 200) {
        const modifiedSlots = slotsResponse.data.slots?.map((item) => {
          const name = `${item.start_time} - ${item.end_time}`;
          return {
            label: name,
            value: name,
          };
        });
        setSlotsOptions(modifiedSlots);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return toast.error("Unable to get appointment slots");
        }
        if (error.response.status === 500) {
          return toast.error("Internal server error");
        }
      }
    }
  };

  // const classfilter=open?"d-flex flex-column "
  const rowdataAlign = { verticalAlign: "middle" };

  const getAppointments = async (pageData, rowsPerPageData) => {
    const submitStatus =
      selectedStatus &&
      selectedStatus.map((item) => {
        return item === "Scheduled"
          ? 1
          : item === "Rescheduled"
          ? 2
          : item === "Cancelled"
          ? 3
          : 4;
      });

    try {
      const response = await axios.post(
        Global.base_url + "/appointments",
        {
          currentPage: pageData + 1,
          pageSize: rowsPerPageData,
          search: search,
          status: submitStatus.length ? submitStatus : [],
        },
        config
      );

      if (response.status === 200) {
        setAppointments(response.data.data);
        setPaginationData(response.data.pagination);
        setPage(response.data.pagination.currentPage - 1);
        setRowsPerPage(response.data.pagination.pageSize);
        setAnchorEl(null);

        let sortedStatus = Object.keys(response.data.filter.status).map(
          (item) => {
            return {
              name: item,
              // checked: false,
            };
          }
        );

        setFilterData({
          status: sortedStatus,
        });

        // setPaginationData(response.data.pagination);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return toast.error("Unable to get Appointments");
        }
        if (error.response.status === 500) {
          return toast.error("Internal server error");
        }
      }
    }
  };



  //export 

 

  // Function to fetch data from all pages
  const fetchAllData = async () => {
    
    let allData = [];
    let currentPage = 1;
    let totalPages = paginationData.totalPages || 1; // Set total pages if available in response

     const submitStatus =
      selectedStatus &&
      selectedStatus.map((item) => {
        return item === "Scheduled"
          ? 1
          : item === "Rescheduled"
          ? 2
          : item === "Cancelled"
          ? 3
          : 4;
      });

    while (currentPage <= totalPages) {

      try {
        setExportLoading(true);
        // Fetch data for each page
        const response = await axios.post(Global.base_url + "/appointments", {
          currentPage: currentPage,
          pageSize: 1000,
          search: search,
          status: submitStatus.length ? submitStatus : [],
        }
          ,
        config
        );

        if (response.status === 200) {
          // Append the data from the current page to the allData array
          allData = [...allData, ...response.data.data];

          // Update pagination data from API response
          setPaginationData(response.data.pagination);

          // Move to next page
          currentPage++;
          setExportLoading(false)
        }
      } catch (error) {
        console.error('Error fetching data for page', currentPage, error);
        setLoading(false);
        setExportLoading(false)
        return; // Exit early on error
      }
    }

    return allData;
  };

    const exportToExcel = async () => {
    // Fetch all data (across all pages)
    const allData = await fetchAllData();
    
    // Map the appointments data to include only the desired fields
    const filteredData = allData.map((appointment) => ({
      "Appointment Id": appointment.appointment_id,
      "Patient Id": appointment.patient_id,
      "Patient Name": `${appointment.firstname} ${appointment.lastname}`,
      gender: appointment.gender,
      "Mobile Number": appointment.mobile,
      Email: appointment.email,
      Relationship: appointment.relationship,
      "Doctor Name": `${appointment.doctor_firstname} ${appointment.doctor_lastname}`,
      "Appointment Date": new Date(appointment.appointment_date).toLocaleDateString(),
      Slot: appointment.slot,
      Hospital: appointment.lab_name,
      Status: `${
        appointment.appointment_status === 1
          ? "Scheduled"
          : appointment.appointment_status === 2
          ? "Rescheduled"
          : appointment.appointment_status === 3
          ? "Cancelled"
          : "Completed"
      }`,
      "Created At": new Date(appointment.created_at).toLocaleDateString(),
    }));

    // Convert the filtered data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Appointments");

    // Export to Excel file
    XLSX.writeFile(workbook, "appointments.xlsx");
  };
  



  // initial values for input fields
  const initialValues =
    // condition for add or edit modal
    // editUser index indicates to existing products
    editUser.index !== ""
      ? {
          appointment_id: editUser.data.appointment_id,
          appointment_date: moment(
            editUser.data.appointment_date,
            "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'"
          ).format("YYYY-MM-DD"),
          slot: {
            label: editUser.data.slot,
            value: editUser.data.slot,
          },
          appointment_status: {
            label:
              editUser.data.appointment_status === 1
                ? "Scheduled"
                : editUser.data.appointment_status === 2
                ? "Rescheduled"
                : editUser.data.appointment_status === 3
                ? "Cancelled"
                : "Completed",
            value: editUser.data.appointment_status,
          },

          comments: editUser.data.comments ?? "",
        }
      : {
          appointment_id: "",
          appointment_date: null,
          slot: null,
          appointment_status: null,
          comments: "",
        };

  function MyVerticallyCenteredModal({
    editUser,
    setRefresh,
    initialValues,
    setEdit,
  }) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    const [submitLoader, setSubmitLoader] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("");

    useEffect(() => {
      if (editUser.index !== "") {
        setSelectedStatus(editUser.data.appointment_status);
      }
    }, [editUser]);

    const required = <span style={{ color: "red" }}>*</span>;
    function closeModal() {
      setEdit({
        data: "",
        index: "",
      });
      setShowEditModal(false);
    }

    return (
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <p className="boldFont">
              {editUser.index !== "" ? "Edit Appointment" : "Add Appointment"}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={
              selectedStatus === 2
                ? yup.object().shape({
                    comments: yup.string().required("comment is required"),
                    appointment_date: yup
                      .date()
                      .required("date is required")
                      .nullable(),
                    slot: yup.object().required("slot is required").nullable(),
                    appointment_status: yup
                      .object()
                      .required("status is required")
                      .nullable(),
                  })
                : selectedStatus === 3
                ? yup.object().shape({
                    appointment_status: yup
                      .object()
                      .required("status is required")
                      .nullable(),
                    comments: yup.string().required("comment is required"),
                  })
                : yup.object().shape({
                    appointment_status: yup
                      .object()
                      .required("status is required")
                      .nullable(),
                  })
            }
            onSubmit={async (values, formik) => {
              setSubmitLoader(true);

              const url =
                editUser.index !== ""
                  ? Global.base_url + "/appointments/update"
                  : Global.base_url + "/appointments/add";

              const data =
                selectedStatus === 2
                  ? {
                      appointment_id: values.appointment_id,
                      appointment_status: values.appointment_status.value,
                      comments: values.comments,
                      slot: values.slot.value,
                      appointment_date: moment(values.appointment_date).format(
                        "YYYY-MM-DD"
                      ),
                    }
                  : selectedStatus === 3
                  ? {
                      appointment_id: values.appointment_id,
                      appointment_status: values.appointment_status.value,
                      comments: values.comments,
                    }
                  : {
                      appointment_id: values.appointment_id,
                      appointment_status: values.appointment_status.value,
                    };

              try {
                const response = await axios.post(url, data, config2);
                if (response.status === 200) {
                  setSubmitLoader(false);
                  closeModal();
                  editUser.index !== ""
                    ? toast.success("Appointment Updated Successfully")
                    : toast.success("Appointment Added Successfully");
                  return setRefresh(Date.now() + 1);
                }
              } catch (error) {
                if (error.response && error.response.status === 401) {
                  toast.error(error.response.data.msg);
                }
                if (error.response && error.response.status === 500) {
                  toast.error("Internal server error");
                }
                return setSubmitLoader(false);
              }
            }}
          >
            {(formik) => {
              return (
                <>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-xl-6 col-lg-6">
                        <div
                          className="inputBox"
                          style={{
                            position: "relative",
                            marginTop: 0,
                            marginBottom: "10px",
                          }}
                        >
                          <label
                            htmlFor="holiday-name"
                            className="semiFont login__label"
                          >
                            Appointment Status {required}
                          </label>
                          <Select
                            value={formik.values.appointment_status}
                            name="Status"
                            onChange={(p) => {
                              formik.setFieldValue("appointment_status", p);
                              setSelectedStatus(p.value);
                              // console.log("type ", p.value);
                            }}
                            id="inputDropdown"
                            placeholder="Select Status"
                            options={statusOptions}
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                marginTop: "5px",
                                padding: "10px 10px",
                                border: formik.errors.appointment_status
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                "&:active": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                                "&:hover": {
                                  outline: "none",
                                  boxShadow: "none",
                                },
                              }),
                              option: (base, state) => ({
                                ...base,
                                backgroundColor: state.isFocused
                                  ? "#eee"
                                  : "#fff",
                                padding: "8px",
                                display: "flex",
                                fontFamily: "semiFont",
                                alignItems: "center",
                                fontSize: "16px",
                                zIndex: 9999,
                                color: state.isFocused ? "#183b56" : "#183b56",
                                "&:hover": {
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                },
                              }),
                            }}
                            className="admin__Addproduct--dropdown "
                          />
                          {formik.errors.appointment_status && (
                            <div className="err">
                              <p>{formik.errors.appointment_status}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {selectedStatus === 2 ? (
                        <div className="col-12 col-xl-6 col-lg-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="last-name"
                              className="semiFont login__label"
                            >
                              Appointment Date {required}
                            </label>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <DatePicker
                                //   inputVariant="filled"
                                disablePast
                                openTo="year"
                                //   className={classes.root}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                                // minDate={moment("01-01-1920", "DD-MM-YYYY")}
                                // maxDate={moment("01-01-2010", "DD-MM-YYYY")}
                                format="DD/MM/YYYY"
                                views={["year", "month", "date"]}
                                value={formik.values.appointment_date}
                                onChange={(date) =>
                                  formik.setFieldValue("appointment_date", date)
                                }
                                style={{
                                  border: formik.errors
                                    ? formik.errors.appointment_date
                                      ? "1px solid #dc3545"
                                      : "1px solid #c3cad9"
                                    : "1px solid #c3cad9",
                                  borderRadius: "8px",
                                  marginTop: "5px",
                                  padding: "2px",
                                }}
                                error={false}
                                helperText={null}
                                placeholder="DD/MM/YYYY"
                              />
                            </MuiPickersUtilsProvider>
                            {formik.errors.appointment_date && (
                              <div className="err">
                                <p>{formik.errors.appointment_date}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : selectedStatus === 3 ? (
                        <div className="col-12 col-xl-6 col-lg-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <div className="label__row d-flex align-items-center justify-content-between">
                              <label
                                htmlFor="comments"
                                className="semiFont login__label"
                              >
                                Comment {required}
                              </label>
                            </div>
                            <textarea
                              type="email"
                              id="comments"
                              name="comments"
                              placeholder="Comment"
                              // className="form-control signup__input"
                              value={formik.values.comments}
                              rows="4"
                              style={{
                                border: formik.errors.comments
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "5px",
                                width: "auto",
                                resize: "none",
                                borderRadius: "8px",
                                padding: "10px",
                                color: "#183b56",
                                fontSize: "17px",
                                fontFamily: "mediumFont",
                              }}
                              onChange={formik.handleChange("comments")}
                            />
                            {formik.errors.comments && (
                              <div className="err">
                                <p>{formik.errors.comments}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {selectedStatus === 2 ? (
                      <div className="row">
                        <div className="col-12 col-xl-6 col-lg-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <label
                              htmlFor="holiday-name"
                              className="semiFont login__label"
                            >
                              Slot {required}
                            </label>
                            <Select
                              value={formik.values.slot}
                              name="Status"
                              onChange={(p) => {
                                formik.setFieldValue("slot", p);
                                // console.log("type ", p.value);
                              }}
                              id="inputDropdown"
                              placeholder="Select Status"
                              options={slotsOptions}
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  marginTop: "5px",
                                  padding: "10px 10px",
                                  border: formik.errors.slot
                                    ? "1px solid red"
                                    : "1px solid #c3cad9",
                                  "&:active": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                  "&:hover": {
                                    outline: "none",
                                    boxShadow: "none",
                                  },
                                }),
                                option: (base, state) => ({
                                  ...base,
                                  backgroundColor: state.isFocused
                                    ? "#eee"
                                    : "#fff",
                                  padding: "8px",
                                  display: "flex",
                                  fontFamily: "semiFont",
                                  alignItems: "center",
                                  fontSize: "16px",
                                  zIndex: 9999,
                                  color: state.isFocused
                                    ? "#183b56"
                                    : "#183b56",
                                  "&:hover": {
                                    backgroundColor: state.isFocused
                                      ? "#eee"
                                      : "#fff",
                                  },
                                }),
                              }}
                              className="admin__Addproduct--dropdown "
                            />
                            {formik.errors.slot && (
                              <div className="err">
                                <p>{formik.errors.slot}</p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-xl-6 col-lg-6">
                          <div
                            className="inputBox"
                            style={{
                              position: "relative",
                              marginTop: 0,
                              marginBottom: "10px",
                            }}
                          >
                            <div className="label__row d-flex align-items-center justify-content-between">
                              <label
                                htmlFor="comments"
                                className="semiFont login__label"
                              >
                                Comment {required}
                              </label>
                            </div>
                            <textarea
                              type="email"
                              id="comments"
                              name="comments"
                              placeholder="Comment"
                              // className="form-control signup__input"
                              value={formik.values.comments}
                              rows="4"
                              style={{
                                border: formik.errors.comments
                                  ? "1px solid red"
                                  : "1px solid #c3cad9",
                                marginTop: "5px",
                                width: "auto",
                                resize: "none",
                                borderRadius: "8px",
                                padding: "10px",
                                color: "#183b56",
                                fontSize: "17px",
                                fontFamily: "mediumFont",
                              }}
                              onChange={formik.handleChange("comments")}
                            />
                            {formik.errors.comments && (
                              <div className="err">
                                <p>{formik.errors.comments}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div
                      className={
                        "mt-3 d-flex flex-column flex-sm-row justify-content-end"
                      }
                    >
                      <div className="d-flex flex-sm-row addproduct__button--aligncenter">
                        <div className="form-group ">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={formik.handleSubmit}
                            disabled={!formik.dirty || submitLoader}
                            style={{
                              backgroundColor: !formik.dirty
                                ? "#777"
                                : "#1565d8",
                              color: "#fff",
                              padding: submitLoader ? "7px 40px" : "10px 15px",
                              // width: "150px",
                              textTransform: "initial",
                              marginRight: "10px",
                            }}
                          >
                            {submitLoader ? (
                              <div
                                className="spinner-border text-light"
                                // style={{}}
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "Update/Save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      {loading ? (
        <div className="center__items" style={{ marginTop: "50px" }}>
          <Loader type="TailSpin" color="#1565d8" height={45} width={45} />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            // newestOnTop={false}
            closeOnClick
            rtl={false}
          />
          <MyVerticallyCenteredModal
            editUser={editUser}
            initialValues={initialValues}
            setRefresh={setRefresh}
            setEdit={setEdit}
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
          />
          <DeleteModal
            show={deleteModal}
            onHide={() => setDeleteModal(false)}
            selectedData={editUser.data?.id}
            refresh={setRefresh}
            apiUrl={"/holidays/delete"}
            name={"Holiday"}
            // id={"category_id"}
          />
          <section className="filter-section testreport">
            <div className="sectionHeader p-3">
              <h2 className="hkBoldFont" style={{ color: "#161616" }}>
                Appointments
              </h2>
              <p className="mediumFont mt-2" style={{ color: "#515151" }}>
                {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. */}
              </p>
            </div>

            <div className="d-flex align-items-center justify-content-end  flex-md-row  mr-0 pt-2 px-0 pb-0">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                  //   border: showFilter ? "1px solid #c3cad9" : 0,
                  borderLeft: "none",
                  borderBottom: "none",
                }}
                className="search__testcontainer"
                // id={open ? "searchTransition" : "searchStatic"}
              >
                <input
                  type="text"
                  name="search"
                  id={"search__input"}
                  placeholder="Search Name"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => {
                    // detects Enter key or numberpad enter key
                    if (e.code === "Enter") {
                      getAppointments(page, rowsPerPage);
                    }
                  }}
                />
              </div>
              <div
                className="filter__container"
                style={{ backgroundColor: open ? "#F3F3F3" : "#fff" }}
              >
                <div
                  className="filter__icon p-3"
                  onClick={filterClick}
                  style={{ backgroundColor: open ? "#F3F3F3" : "#fff" }}
                >
                  {selectedStatus.length !== 0 ? (
                    <Badge badgeContent={selectedStatus.length} color="primary">
                      <IconContext.Provider
                        value={{
                          color: "#333",
                          size: "25px",
                        }}
                      >
                        <BsSliders />
                      </IconContext.Provider>
                    </Badge>
                  ) : (
                    <IconContext.Provider
                      value={{
                        color: "#333",
                        size: "25px",
                      }}
                    >
                      <BsSliders />
                    </IconContext.Provider>
                  )}
                </div>

                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={filterClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: {
                      // width: "45%",
                      backgroundColor: "#f3f3f3",
                      // display: "flex",
                      // justifyContent: 'space-between'
                    },
                  }}
                >
                  <div className="pop__container">
                    {selectedStatus.length ? (
                      <div className="selected__items px-4 pt-4 d-flex align-items-center semiFont">
                        <div className="batch__item d-flex align-items-center">
                          <p className="mr-2 mt-1">{selectedStatus.length}</p>
                          <div
                            className="icons"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSelectedStatus([]);
                            }}
                          >
                            <IconContext.Provider
                              value={{
                                color: "#fff",
                                size: "20px",
                              }}
                            >
                              <CgClose />
                            </IconContext.Provider>
                          </div>
                        </div>
                        <p className="ml-2">Filteres are selected</p>
                      </div>
                    ) : null}

                    <div className="row px-4 pb-4 pt-4 justify-content-lg-between">
                      <div className="col">
                        <p className="semiFont pop__header">Status</p>
                        <div className="filter__items mt-2 d-flex flex-column">
                          {filterData &&
                            filterData.status &&
                            filterData.status.map((item, i) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        selectedStatus.includes(item.name)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        const { checked } = e.target;
                                        if (checked) {
                                          setSelectedStatus([
                                            ...selectedStatus,
                                            item.name,
                                          ]);
                                        } else {
                                          setSelectedStatus(
                                            selectedStatus &&
                                              selectedStatus.filter((list) => {
                                                return item.name !== list;
                                              })
                                          );
                                        }
                                      }}
                                      name={item.name}
                                      color="primary"
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    <div className="carbon__buttoncontainer">
                      {/* {location.state.editable ? ( */}
                      <Button
                        variant="contained"
                        className="carbon__secondarybtn carbon__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          //   padding: "25px ",
                          color: "#fff",
                          //   border: "1px solid #1565d8",
                          //   marginRight: "15px",
                        }}
                        onClick={() => {
                          setSelectedStatus([]);
                        }}
                      >
                        <p>Reset Filter</p>
                      </Button>
                      {/* ) : null} */}

                      <Button
                        variant="contained"
                        className="carbon__primarybtn carbon__btn"
                        id="admin-reportview-download-btn"
                        style={{
                          color: "#fff",
                        }}
                        onClick={() => getAppointments(page, rowsPerPage)}
                      >
                        <p className="carbon__text">Apply Filter</p>
                        {/* Yes */}
                      </Button>
                    </div>
                  </div>
                </Popover>
                {/* </> */}
                </div>
                {exportLoading ?  <div
                           className="export__button"
                          >
                         <img src={LoadingGif} alt="" style={{width:"25px", height:"25px"}} />
                </div> : 
                           <button className="export__button" onClick={exportToExcel}>
                <img src={ExportIcon}  alt="export icon" style={{width:"25px", height:"25px"}}/>
              </button>
                          }
             
              {/* {} */}
              {/* {userAccess &&
              _.some(userAccess.privileges, [
                "privilege_name",
                "ADD APPOINTMENTS",
              ]) ? (
                <Button
                  variant="contained"
                  color="secondary"
                  // className="primary__button
                  type="submit"
                  startIcon={
                    <IconContext.Provider
                      value={{
                        color: "#fff",
                        size: "25px",
                      }}
                    >
                      <MdAdd />
                    </IconContext.Provider>
                  }
                  style={{ width: "250px" }}
                  className={[classes.rootAbsolute, "primary__button"]}
                  onClick={() => {
                    setEdit({
                      data: "",
                      index: "",
                    });
                    setShowEditModal(true);
                  }}
                >
                  Add Appointment
                </Button>
              ) : null} */}
            </div>
          </section>
          <section
            className="admin-product-filter-section table-responsive fixHeader
"
          >
            <table
              ref={tableRef}
              className="table  text-center  admin-product_usertable mb-0"
              style={{
                backgroundColor: "white",
              }}
              id="bill-table"
            >
              <thead>
                <tr
                  id="admin-product-row"
                  style={{ border: "none", backgroundColor: "#E0E0E0" }}
                >
                  <th scope="col" className="admin-product-row_table-header">
                    ID
                  </th>
                  <th scope="col" className="admin-product-row-table-header">
                    name
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header admin-product-row-price-col"
                  >
                    Date
                  </th>

                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Slot
                    </th>
                     <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                  Event Type
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Doctor name
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Hospital
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="admin-product-row-table-header  admin-product-row-status-col"
                  >
                    Created At
                  </th>
                  {(userAccess &&
                    _.some(userAccess.privileges, [
                      "privilege_name",
                      "EDIT APPOINTMENTS",
                    ])) ||
                  _.some(userAccess.privileges, [
                    "privilege_name",
                    "DELETE APPOINTMENTS",
                  ]) ? (
                    <th
                      scope="col"
                      className="admin-product-row-action-col admin-product-row-table-header"
                    ></th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {appointments.length ? (
                  appointments.map((data, i) => {
                    if (i > rowsPerPage - 1) {
                      return;
                    }
                    const color =
                      data.appointment_status === 1 ||
                      data.appointment_status === 2
                        ? "#ffb100"
                        : data.appointment_status === 3
                        ? "red"
                        : "#03A33B";

                    //   const borderLeft = `5px solid ${color}`;
                    const borderBottom = `1px solid #B3CDFF`;

                    return (
                      <>
                        <tr
                          key={data.appointment_id + Math.random()}
                          style={{
                            //   borderLeft,
                            borderBottom,
                            backgroundColor: "white",
                          }}
                          className="admin-product-row-table-row-hover "
                        >
                          <td
                            scope="row"
                            id="admin-product-rd"
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            <p
                              data-toggle="collapse"
                              data-target={`#accordion` + i}
                              className="clickable cursor"
                              id="button-open"
                              // style={{ display: "none" }}
                            >
                              <IconContext.Provider
                                value={{
                                  color: "#4059E2",
                                  size: "25px",
                                  style: { marginBottom: "3px" },
                                }}
                              >
                                <IoMdAddCircle className="toggle-row-icon" />
                              </IconContext.Provider>
                            </p>{" "}
                            AID{data.appointment_id}
                          </td>
                          <td
                            className="admin-product-row-table-row-data mediumFont"
                            style={rowdataAlign}
                          >
                            {data.firstname} {data.lastname}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-price-col mediumFont"
                            style={rowdataAlign}
                          >
                            {moment(data.appointment_date).format("YYYY-MM-DD")}
                          </td>

                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.slot}
                          </td>

                           <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.event_name}
                          </td>

                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.doctor_firstname} {data?.doctor_lastname}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={rowdataAlign}
                          >
                            {data.lab_name}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={{ color, ...rowdataAlign }}
                          >
                            {data.appointment_status === 1
                              ? "Scheduled"
                              : data.appointment_status === 2
                              ? "Rescheduled"
                              : data.appointment_status === 3
                              ? "Cancelled"
                              : "Completed"}
                          </td>
                          <td
                            className="admin-product-row-table-row-data admin-product-row-status-col mediumFont"
                            style={rowdataAlign}
                          >
                            {/* {data.created_at} */}
                            {moment(data.created_at).format(
                              "YYYY-MM-DD hh:mm A"
                            )}
                          </td>

                          {userAccess &&
                          _.some(userAccess.privileges, [
                            "privilege_name",
                            "EDIT APPOINTMENTS",
                          ]) ? (
                            <td
                              className="admin-product-row-action-col py-3 "
                              // colSpan={2}
                              style={rowdataAlign}
                            >
                              <div className="iconButtons__container justify-content-center">
                                {_.some(userAccess.privileges, [
                                  "privilege_name",
                                  "EDIT APPOINTMENTS",
                                ]) ? (
                                  <Tooltip
                                    title={"Edit"}
                                    placement="top-center"
                                  >
                                    <IconButton
                                      className="edit__IconButton"
                                      onClick={() => {
                                        setEdit({
                                          data: data,
                                          index: i,
                                        });
                                        setShowEditModal(true);
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={{
                                          color: "#1565d8",
                                          size: "30px",
                                        }}
                                      >
                                        <BiEditAlt />
                                      </IconContext.Provider>
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                              </div>
                            </td>
                          ) : null}
                        </tr>
                        <tr
                          id={"accordion" + i}
                          className="collapse  admin-product-hided-row"
                          key={data.appointment_id + Math.random()}
                        >
                          <td colSpan="6" style={rowdataAlign}>
                            <ul
                              className="admin-product-row-table-hide-list text-left mediumFont"
                              style={{ color: "#525252" }}
                            >
                              <li
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                                className="admin-product-row-hided-col-price"
                              >
                                <div className="row justify-content-start ml-3">
                                  <p className="col-6  text-center">Date</p>
                                  <div className="col-6  text-left">
                                    {moment(data.appointment_date).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </div>
                                </div>
                              </li>

                              <li
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Slot:
                                  </div>
                                  <div className="col-6  text-left">
                                    {data.slot}
                                  </div>
                                </div>
                              </li>
                              <li
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Doctor name:
                                  </div>
                                  <div className="col-6  text-left">
                                    {data.doctor_firstname}{" "}
                                    {data?.doctor_lastname}
                                  </div>
                                </div>
                              </li>
                              <li
                                style={{
                                  // borderBottom: "1px solid #B3CDFF",
                                  padding: "10px",
                                }}
                              >
                                <div className="row justify-content-start ml-3">
                                  <div className="col-6  text-center">
                                    Status:
                                  </div>
                                  <div
                                    className="col-6  text-left"
                                    style={{ color }}
                                  >
                                    {data.appointment_status === 1
                                      ? "Scheduled"
                                      : data.appointment_status === 2
                                      ? "Rescheduled"
                                      : data.appointment_status === 3
                                      ? "Cancelled"
                                      : "Completed"}
                                  </div>
                                </div>
                              </li>

                              {userAccess &&
                              _.some(userAccess.privileges, [
                                "privilege_name",
                                "EDIT APPOINTMENTS",
                              ]) ? (
                                <li
                                  key={data.package_id + Math.random()}
                                  style={{
                                    // borderBottom: "1px solid #B3CDFF",
                                    padding: "10px",
                                  }}
                                >
                                  <div className="row justify-content-center mt-2 ml-3">
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3  text-center iconButtons__container">
                                      {_.some(userAccess.privileges, [
                                        "privilege_name",
                                        "EDIT APPOINTMENTS",
                                      ]) ? (
                                        <Tooltip
                                          title={"Edit"}
                                          placement="top-center"
                                        >
                                          <IconButton
                                            className="edit__IconButton"
                                            onClick={() => {
                                              history.push({
                                                pathname: "/admin/doctor-view",
                                                state: {
                                                  editable: true,
                                                  data: data,
                                                  userAccess: userAccess,
                                                },
                                              });
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                color: "#1565d8",
                                                size: "30px",
                                              }}
                                            >
                                              <BiEditAlt />
                                            </IconContext.Provider>
                                          </IconButton>
                                        </Tooltip>
                                      ) : null}
                                    </div>
                                  </div>
                                </li>
                              ) : null}
                            </ul>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <div
                    className="center__items p-5"
                    style={{ flexDirection: "column" }}
                  >
                    <img src={emptyUser} alt="empty-user" />
                    <p
                      style={{
                        color: "#D2D2D2",
                        padding: "20px",
                        fontSize: "20px",
                        fontFamily: "mediumFont",
                      }}
                    >
                      {userAccess &&
                      _.some(userAccess.privileges, [
                        "privilege_name",
                        "VIEW APPOINTMENTS",
                      ])
                        ? "Appointments not found..."
                        : "You don't have access"}
                    </p>
                  </div>
                )}
              </tbody>
            </table>
          </section>

            
          {paginationData && paginationData.totalData > 5 ? (
            <div className="d-flex flex-row align-items-center justify-content-end mb-4">
              <TablePagination
                component="div"
                count={paginationData ? paginationData.totalData : 0}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 50]}
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Appointments;
